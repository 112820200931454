import React from "react";
import "./Support.css";

const SupportDetails = ({ supportDetails }) => {
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0rem 3rem 2rem 0",
          }}
        >
          Client name: <span>{supportDetails.clientName}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2rem 3rem 2rem 0",
          }}
        >
          Support status:{" "}
          <span>
            {supportDetails.supportStatus ? (
              <p className="solved">{supportDetails.supportStatus}</p>
            ) : (
              <p className="pending">{supportDetails.supportStatus}</p>
            )}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2rem 3rem 2rem 0",
          }}
        >
          Email: <span>{supportDetails.userInformationResponse.email}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2rem 3rem 2rem 0",
          }}
        >
          Phone: <span>{supportDetails.userInformationResponse.phone}</span>
        </div>

        <p
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2rem 3rem 2rem 0",
            gap: "0.5rem",
          }}
        >
          Message: <p style={{ width: "80%" }}>{supportDetails.message}</p>
        </p>
        {supportDetails.confirmationNote ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "2rem 3rem 2rem 0",
              gap: "0.5rem",
            }}
          >
            Confirmation note:{" "}
            <p style={{ width: "80%" }}>{supportDetails.confirmationNote}</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SupportDetails;
