import React, { useEffect, useState } from "react";
import Model from "../UI/Model/Model";
import "./Support.css";
import { Table, Segmented, Space, Tooltip, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AiOutlineCheck } from "react-icons/ai";
import SendConfirmationNote from "./SendConfirmationNote";
import SupportDetails from "./SupportDetails";
import { EyeOutlined } from "@ant-design/icons";
import TableSkeleton from "../UI/TableSkeleton/tableSkelaton";

const column = [
  {
    title: "Ticket No",
    dataIndex: "ticketNo",
  },
  {
    title: "Client Name",
    dataIndex: "clientName",
  },
  {
    title: "User Type",
    dataIndex: "userType",
  },
  {
    title: "User Email",
    dataIndex: "email",
  },
  {
    title: "Added Date",
    dataIndex: "addedDate",
  },
  {
    title: "Status",
    dataIndex: "supportStatus",
  },
  {
    title: "Action",
    dataIndex: "Action",
  },
];

const Support = () => {
  const supportState = useSelector((state) => state.SupportReducer);
  const dispatch = useDispatch();
  const [userType, setUserType] = useState("All");
  const [supportId, setSupportId] = useState();
  const [supportStatus, setSupportStatus] = useState("Both");
  const [supportDetailsObject, setSupportDetailsObject] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSupportModalVisible, setSupportModalVisible] = useState(false);

  useEffect(() => {
    dispatch({
      type: "GET_SUPPORT_DETAILS_REQUEST",
      payload: {
        userType: userType.toUpperCase(),
        supportStatus: supportStatus.toUpperCase(),
      },
    });
  }, [userType, supportStatus]);

  const onUserTypeSegmentChange = (key) => {
    setUserType(key);
  };
  const onSupportStatusSegmentChange = (key) => {
    setSupportStatus(key);
  };

  const data = supportState.supportData?.data?.map((supportObj) => {
    return {
      key: supportObj.supportId,
      ticketNo: supportObj.ticketNo,
      clientName: supportObj.clientName,
      userType: supportObj.userType,
      email: supportObj.userInformationResponse.email,
      addedDate: moment(supportObj.addedDate).format("YYYY-MM-DD"),
      supportStatus:
        supportObj.supportStatus.toUpperCase() === "PENDING" ? (
          <p className="pending">{supportObj.supportStatus}</p>
        ) : (
          <p className="solved">{supportObj.supportStatus}</p>
        ),
      Action: (
        <Space size="middle">
          {supportObj.supportStatus === "SOLVED" ? (
            ""
          ) : (
            <Tooltip title="Mark as Solved">
              <a onClick={() => solveConfirmation(supportObj.supportId)}>
                <div className="bordered">
                  <AiOutlineCheck />
                </div>
              </a>
            </Tooltip>
          )}
          <Tooltip title="See Details">
            <a onClick={() => seeDetails(supportObj)}>
              <div className="bordered">
                <EyeOutlined />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });

  const seeDetails = (supportObj) => {
    setSupportModalVisible(true);
    setSupportDetailsObject(supportObj);
  };

  const solveConfirmation = (id) => {
    setSupportId(id);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSupportCancel = () => {
    setSupportModalVisible(false);
  };
  console.log(supportState);
  return (
    <Model>
      <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
        <div style={{ display: "flex", gap: "2rem" }}>
          <h3>Support Management</h3>
          <Segmented
            size="medium"
            options={["All", "Intern", "Recruiter"]}
            onChange={onUserTypeSegmentChange}
            style={{ height: "34px", fontSize: "14px" }}
          />
          <Segmented
            size="medium"
            options={["Both", "Solved", "Pending"]}
            onChange={onSupportStatusSegmentChange}
            style={{ height: "34px", fontSize: "14px" }}
          />
        </div>
        {supportState.isLoading ? (
          <TableSkeleton />
        ) : (
          <Table bordered columns={column} dataSource={data} />
        )}
      </div>
      <Modal
        title="Confirmation Note"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <SendConfirmationNote
          userType={userType}
          supportStatus={supportStatus}
          supportId={supportId}
          onHandleCancel={handleCancel}
        />
      </Modal>
      <Modal
        title="Support Details"
        visible={isSupportModalVisible}
        onCancel={handleSupportCancel}
        footer={null}
      >
        <SupportDetails supportDetails={supportDetailsObject} />
      </Modal>
    </Model>
  );
};

export default Support;
