import React from "react";
import { Form, Input, Button } from "antd";
import { useDispatch } from "react-redux";

const SendConfirmationNote = ({
  onHandleCancel,
  supportId,
  userType,
  supportStatus,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const submitConfirmationNoteHandler = (values) => {
    dispatch({
      type: "CHANGE_SUPPORT_STATUS_AS_SOLVED_REQUEST",
      payload: {
        userType: userType.toUpperCase(),
        supportStatus: supportStatus.toUpperCase(),
      },
      supportId,
      confirmationNote: values.confirmationNote,
    });
    form.resetFields();
    onHandleCancel();
  };
  return (
    <>
      <Form form={form} onFinish={submitConfirmationNoteHandler}>
        <Form.Item
          label="Confirmation note"
          name="confirmationNote"
          rules={[
            {
              required: true,
              message: "Please enter confirmation note!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button
            style={{ backgroundColor: "#009A9A", color: "white" }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SendConfirmationNote;
