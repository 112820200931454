import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  dashboardData: {},
};

const DashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "GET_DASHBOARD_DATA_REQUEST": {
      return {
        isLoading: true,
      };
    }
    case "GET_DASHBOARD_DATA_REQUEST_SUCCESS": {
      return {
        isLoading: false,
        dashboardData: action.payload,
      };
    }
    case "GET_DASHBOARD_DATA_REQUEST_SUCCESS": {
      message.error("Unable to load dashboard!");
      return {
        isLoading: false,
      };
    }
    default:
      return INIT_STATE;
  }
};

export default DashboardReducer;
