import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
};

const MassNotificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADD_MASS_NOTIFICATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_MASS_NOTIFICATION_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Successfully added!");
      return {
        isLoading: false,
      };
    }
    case "ADD_MASS_NOTIFICATION_REQUEST_FAILURE": {
      action.payload.message
        ? message.error(action.payload.message)
        : message.error("Unable to add mass notification!");

      console.log("from mass notifail reducer", action.payload);
      return {
        isLoading: false,
      };
    }
    default:
      return INIT_STATE;
  }
};

export default MassNotificationReducer;
