import {
  ADMIN_AUTH_FAILURE,
  ADMIN_AUTH_REQUEST,
  ADMIN_AUTH_SUCCESS,
  PRIVACY_POLICY_REQUEST,
  PRIVACY_POLICY_REQUEST_SUCCESS,
  PRIVACY_POLICY_REQUEST_FAILURE,
} from "./actionType";

// login
export const adminLoginRequest = (adminCredential) => {
  console.log(adminCredential);
  return {
    type: ADMIN_AUTH_REQUEST,
    payload: {
      adminCredential,
    },
  };
};
export const adminLoginSuccess = (data) => {
  return {
    type: ADMIN_AUTH_SUCCESS,
    payload: { data },
  };
};
export const adminLoginFailure = (error) => {
  return {
    type: ADMIN_AUTH_FAILURE,
    payload: { error },
  };
};

// privacy policy
export const getPrivacyPolicy = () => {
  return {
    type: "PRIVACY_POLICY_REQUEST",
  };
};

export const addPrivacyPolicy = () => {
  return {
    type: "PRIVACY_POLICY_ADD_REQUEST",
  };
};

export const updatePrivacyPolicy = (payload) => {
  return {
    type: "PRIVACY_POLICY_UPDATE_REQUEST",
    payload,
  };
};

//terms and conditions
export const getTermsandConditions = () => {
  return {
    type: "TERMS_AND_CONDITIONS_REQUEST",
  };
};

export const updateTermsAndConditions = (payload) => {
  console.log("frm actions", payload);
  return {
    type: "TERMS_AND_CONDITIONS_UPDATE_REQUEST",
    payload: payload,
  };
};

//job categories
export const listJobCategories = () => {
  return {
    type: "GET_JOB_CATEGORIES_REQUEST",
  };
};
