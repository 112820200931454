import React from "react";
import { Form, Input, Button } from "antd";
import { useDispatch } from "react-redux";

const EditSubCategory = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const submitEditSubCategory = (values) => {
    console.log("edit subcategory values", values);
    dispatch({
      type: "EDIT_JOB_SUBCATEGORY",
      catId: props.record.jobCategoryId,
      id: props.dataObject.subCategoryId,
      payload: values,
    });
    form.resetFields();
    props.onModelCancel();
  };
  return (
    <Form
      form={form}
      onFinish={submitEditSubCategory}
      fields={[
        {
          name: ["subCategoryName"],
          value: props.dataObject.subCategoryName,
        },
      ]}
    >
      <Form.Item
        label="Job sub-category name"
        name="subCategoryName"
        rules={[
          {
            required: true,
            message: "Please enter job sub-category!",
          },
        ]}
      >
        <Input defaultValue={props.dataObject.subCategoryName} />
      </Form.Item>
      <Form.Item name="subCategoryName" wrapperCol={{ offset: 10 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditSubCategory;
