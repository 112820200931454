import React, { useState } from "react";
import Model from "../UI/Model/Model";
import InternManagement from "./InternManagement/InternManagement";
import RecruiterManagement from "./RecruiterManagement/RecruiterManagement";
import { Card, Segmented, Input } from "antd";
const { Search } = Input;

const UserManagement = () => {
  const [segment, setSegment] = useState("Intern");
  const onSegmentChange = (key) => {
    setSegment(key);
  };

  return (
    <Model>
      <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", gap: "2rem" }}>
            <h3>User Management</h3>
            <Segmented
              size="medium"
              options={["Intern", "Recruiter"]}
              onChange={onSegmentChange}
              style={{ height: "34px", fontSize: "14px" }}
            />
          </div>
          {/* <Search
            size="large"
            width={10}
            placeholder="Search Jobs"
            enterButton
          /> */}
        </div>
        {segment === "Intern" ? <InternManagement /> : <RecruiterManagement />}
      </div>
    </Model>
  );
};

export default UserManagement;

{
  /* <Card
style={{
  width: "100%",
}}
tabList={tabList}
activeTabKey={activeTabKey1}
onTabChange={(key) => {
  onTab1Change(key);
}}
>
{contentList[activeTabKey1]}
</Card> */
}
