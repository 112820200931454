import { message } from "antd";
const INIT_STATE = {
  advertisementList: [],
  isLoading: true,
};

const AdvertisementReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "GET_ADVERISEMENT_LIST_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ADVERISEMENT_LIST_REQUEST_SUCCESS":
      return {
        ...state,
        advertisementList: action.payload,
        isLoading: false,
      };
    case "GET_ADVERISEMENT_LIST_REQUEST_FAILURE": {
      message.error("Unable to load advertisement list!", action.payload);
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_NEW_ADVERTISEMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_NEW_ADVERTISEMENT_REQUEST_SUCCESS": {
      message.success("Advertisement added!");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_NEW_ADVERTISEMENT_REQUEST_FAILURE": {
      message.error("Advertisement cannot be added!");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_ADVERTISEMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "UPDATE_ADVERTISEMENT_REQUEST_SUCCESS": {
      message.success("update advertisement success!");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_ADVERTISEMENT_REQUEST_FAILURE": {
      message.error("Advertisement cannot be updated!");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_ADVERTISEMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "DELETE_ADVERTISEMENT_REQUEST_SUCCESS": {
      message.success("Advertisement deleted sucessfully!");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_ADVERTISEMENT_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Unable to delete advertisement!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_ADVERTISEMENT_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_ADVERTISEMENT_STATUS_REQUEST_SUCCESS": {
      message.success("Status changed sucessfully!");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_ADVERTISEMENT_STATUS_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Advertisement cannot be updated!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return INIT_STATE;
  }
};

export default AdvertisementReducer;
