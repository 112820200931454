import React, { useState } from "react";
import "./RecruiterDetails.css";
import { Card, Image } from "antd";
import { GoLocation } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import { CgWebsite } from "react-icons/cg";
import profieImg from "./../../../assets/26323.jpg";

import moment from "moment";
import { icons } from "react-icons";

const tabList = [
  {
    key: "tab1",
    tab: "Contact Information",
  },
  {
    key: "tab2",
    tab: "Detail Information",
  },
];

const RecruiterDetails = ({ companyDetailsObj }) => {
  const [cardTab, setCardTab] = useState("tab1");
  const onTab1Change = (key) => {
    setCardTab(key);
  };
  return (
    <>
      <div className="detailsContainer">
        <div
          style={{ display: "flex", justifyContent: "flex-start", gap: "5rem" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Image
              src={companyDetailsObj.userDetails.userImageUrl}
              height="200px"
              width="200px"
              style={{ objectFit: "contain" }}
            />
            <div>
              <div>
                <h3
                  style={{
                    marginTop: "20px",
                    borderBottom: "1px solid #f0f0f0",
                  }}
                >
                  About company
                </h3>
                <p className="detaisParagraph" style={{ width: "100%" }}>
                  Location:
                  <span>{companyDetailsObj.location}</span>
                </p>
                <p className="detaisParagraph" style={{ width: "100%" }}>
                  Email:
                  <span>{companyDetailsObj.companyEmail}</span>
                </p>
                <p className="detaisParagraph" style={{ width: "100%" }}>
                  Website:
                  <a href={companyDetailsObj.websiteAddress}>
                    {companyDetailsObj.websiteAddress}
                  </a>
                </p>
              </div>
              <h3 style={{ borderBottom: "1px solid #f0f0f0" }}>Services</h3>

              {companyDetailsObj.services.map((service, index) => (
                <p className="detaisParagraph" key={index}>
                  {service}
                </p>
              ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div style={{ display: "flex" }}>
              <p
                className="detaisParagraph"
                style={{ fontWeight: "700", fontSize: "36px", color: "black" }}
              >
                {companyDetailsObj.companyName}
              </p>
              {companyDetailsObj.userDetails.disabled ? (
                <p className="detaisParagraph disabled">Disabled</p>
              ) : (
                <p className="detaisParagraph Active">Active</p>
              )}
            </div>
            <div>
              <h3 style={{ borderBottom: "1px solid #f0f0f0" }}>
                Recruiter Information
              </h3>
              <p className="detaisParagraph">
                Username: <span>{companyDetailsObj.userDetails.fullName}</span>
              </p>
              <p className="detaisParagraph">
                Name: <span>{companyDetailsObj.userDetails.fullName}</span>
              </p>
              <p className="detaisParagraph">
                Gender: <span>{companyDetailsObj.userDetails.gender}</span>
              </p>
              <p className="detaisParagraph">
                Date of Birth:{" "}
                <span>{companyDetailsObj.userDetails.dateOfBirth}</span>
              </p>
            </div>
            <Card
              style={{ width: "100%", marginTop: "15px" }}
              tabList={tabList}
              activeTabKey={cardTab}
              onTabChange={(key) => {
                onTab1Change(key);
              }}
            >
              {cardTab === "tab1" ? (
                <div style={{ marginTop: "10px" }}>
                  <p className="detaisParagraph">
                    Email: <span>{companyDetailsObj.userDetails.email}</span>
                  </p>
                  <p className="detaisParagraph">
                    Phone: <span>{companyDetailsObj.userDetails.phone}</span>
                  </p>
                  <p className="detaisParagraph">
                    Phone without calling code:{" "}
                    <span>
                      {companyDetailsObj.userDetails.phoneWithoutCallingCode}
                    </span>
                  </p>
                </div>
              ) : (
                <div style={{ marginTop: "10px" }}>
                  <p className="detaisParagraph">
                    User type:
                    <span>{companyDetailsObj.userDetails.userType}</span>
                  </p>
                  <p className="detaisParagraph">
                    Added Date:{" "}
                    <span>
                      {companyDetailsObj.userDetails.addedDate
                        ? moment(
                            companyDetailsObj.userDetails.addedDate
                          ).format("DD-MM-YYYY")
                        : ""}
                    </span>
                  </p>
                  <p className="detaisParagraph">
                    Updated Date:{" "}
                    <span>
                      {companyDetailsObj.userDetails.updatedDate
                        ? moment(
                            companyDetailsObj.userDetails.updatedDate
                          ).format("DD-MM-YYYY")
                        : ""}
                    </span>
                  </p>
                  <p className="detaisParagraph">
                    Updated By:{" "}
                    <span>{companyDetailsObj.userDetails.updatedBy}</span>
                  </p>
                  <p className="detaisParagraph">
                    Notification status:{" "}
                    <span>
                      {companyDetailsObj.userDetails.notificationStatus ? (
                        <p className="detaisParagraph disabled">Disabled</p>
                      ) : (
                        <p className="detaisParagraph Active">Active</p>
                      )}
                    </span>
                  </p>
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecruiterDetails;

/* (<div>
              <h3>Contact Information</h3>
              <p>
                Email: <span>{userDetailsObj.userDetails.email}</span>
              </p>
              <p>
                Phone: <span>{userDetailsObj.userDetails.phone}</span>
              </p>
              <p>
                Phone without calling code:{" "}
                <span>
                  {userDetailsObj.userDetails.phoneWithoutCallingCode}
                </span>
              </p>
            </div>)
            (<div>
              <h3>Intern Information</h3>
              <p>
                User type: <span>{userDetailsObj.userDetails.usertype}</span>
              </p>
              <p>
                User ID:: <span>{userDetailsObj.userDetails.userId}</span>
              </p>
              <p>
                Intern ID: <span>{userDetailsObj.internId}</span>
              </p>
              <p>
                Added Date: <span>{userDetailsObj.userDetails.addedDate}</span>
              </p>
              <p>
                Updated Date:{" "}
                <span>{userDetailsObj.userDetails.updatedDate}</span>
              </p>
              <p>
                Updated By: <span>{userDetailsObj.userDetails.updatedBy}</span>
              </p>
              <p>
                Status: <span>{userDetailsObj.userDetails.disabled}</span>
              </p>
            </div>) */
