import { Card, Statistic } from "antd";
import React from "react";
import { useEffect } from "react";
import Model from "../UI/Model/Model";
import { useDispatch, useSelector } from "react-redux";
import intern from "./../../assets/intern2.png";
import recruiter from "./../../assets/recruiter2.png";
import post from "./../../assets/post.png";
import application from "./../../assets/applications.png";
import successfulApplications from "./../../assets/successfulApplication.png";
import dollar from "./../../assets/dollar.png";
import payment from "./../../assets/payment.png";
import CountUp from "react-countup";
import "./Dashboard.css";
const Dashboard = () => {
  const dashboardState = useSelector((state) => state.DashboardReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "GET_DASHBOARD_DATA_REQUEST" });
  }, []);

  console.log("dashboard state", dashboardState.dashboardData);
  return (
    <Model>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          gap: "2rem",
          flexWrap: "wrap",
        }}
      >
        <div className="cardDiv">
          <div className="imageAndParas">
            <div className="imageCenter">
              <img src={intern} alt="" height="70px" />
            </div>
            <div style={{ border: "1px solid gray" }}></div>
            <div className="textAndNumberDiv">
              <div className="textPara">Total Interns</div>
              <div></div>
              <div className="numberPara">
                <CountUp end={dashboardState.dashboardData?.totalIntern} />
              </div>
            </div>
          </div>
        </div>
        <div className="cardDiv">
          <div className="imageAndParas">
            <div className="imageCenter">
              <img src={recruiter} alt="" height="70px" />
            </div>
            <div style={{ border: "1px solid gray" }}></div>
            <div className="textAndNumberDiv">
              <div className="textPara">Total Recruiters</div>
              <div className="numberPara">
                <CountUp end={dashboardState.dashboardData?.totalRecruiter} />
              </div>
            </div>
          </div>
        </div>
        <div className="cardDiv">
          <div className="imageAndParas">
            <div className="imageCenter">
              <img src={post} alt="" height="70px" />
            </div>
            <div style={{ border: "1px solid gray" }}></div>

            <div className="textAndNumberDiv">
              <div className="textPara">Total Posts</div>
              <div className="numberPara">
                <CountUp end={dashboardState.dashboardData?.totalPost} />
              </div>
            </div>
          </div>
        </div>
        {/* adsfjk */}
        <div className="cardDiv">
          <div className="imageAndParas">
            <div className="imageCenter">
              <img src={application} alt="" height="70px" />
            </div>
            <div style={{ border: "1px solid gray" }}></div>

            <div className="textAndNumberDiv">
              <div className="textPara">Total Applications</div>
              <div className="numberPara">
                <CountUp end={dashboardState.dashboardData?.totalApplication} />
              </div>
            </div>
          </div>
        </div>
        <div className="cardDiv">
          <div className="imageAndParas">
            <div className="imageCenter">
              <img src={successfulApplications} alt="" height="70px" />
            </div>
            <div style={{ border: "1px solid gray" }}></div>

            <div className="textAndNumberDiv">
              <div className="textPara">Successful Applications</div>
              <div className="numberPara">
                <CountUp
                  end={dashboardState.dashboardData?.successfulApplication}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cardDiv">
          <div className="imageAndParas">
            <div className="imageCenter">
              <img src={payment} alt="" height="70px" />
            </div>
            <div style={{ border: "1px solid gray" }}></div>

            <div className="textAndNumberDiv">
              <div className="textPara">Pending Payments</div>
              <div className="numberPara">
                <CountUp end={dashboardState.dashboardData?.pendingPayments} />
              </div>
            </div>
          </div>
        </div>
        <div className="cardDiv">
          <div className="imageAndParas">
            <div className="imageCenter">
              <img src={dollar} alt="" height="70px" />
            </div>
            <div style={{ border: "1px solid gray" }}></div>

            <div className="textAndNumberDiv">
              <div className="textPara">Total Earnings</div>
              <div className="numberPara">
                <CountUp end={dashboardState.dashboardData?.totalEarnings} />
              </div>
            </div>
          </div>
        </div>
        <div className="cardDiv">
          <div className="imageAndParas">
            <div className="imageCenter">
              <img src={dollar} alt="" height="70px" />
            </div>
            <div style={{ border: "1px solid gray" }}></div>

            <div className="textAndNumberDiv">
              <div className="textPara">Today Earnings</div>
              <div className="numberPara">
                <CountUp end={dashboardState.dashboardData?.todayEarnings} />
              </div>
            </div>
          </div>
        </div>
        <div className="cardDiv">
          <div className="imageAndParas">
            <div className="imageCenter">
              <img src={application} alt="" height="70px" />
            </div>
            <div style={{ border: "1px solid gray" }}></div>

            <div className="textAndNumberDiv">
              <div className="textPara">Today Applications</div>
              <div className="numberPara">
                <CountUp end={dashboardState.dashboardData?.todayApplication} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Model>
  );
};

export default Dashboard;
