import { mergeMap, catchError, tap, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import Api from "../BaseUrlProvider";
import {
  PRIVACY_POLICY_REQUEST,
  PRIVACY_POLICY_REQUEST_FAILURE,
  PRIVACY_POLICY_REQUEST_SUCCESS,
} from "./actionType";
import { from, of } from "rxjs";
import { adminLoginSuccess, adminLoginFailure } from "./actions";
import { ajax } from "rxjs/ajax";
import { setCookie } from "../FrontendHelper";

// authenticate admin epic

const API = new Api();
// export const authenticateAdminEpic = (action$) =>
//   action$.pipe(
//     ofType(ADMIN_AUTH_REQUEST),
//     mergeMap((action) =>
//       from(API.loginApi(action.payload.adminCredential)).pipe(
//         // tap((response) => console.log("the response data from epic",response.data)),
//         mergeMap((response) => {
//           setCookie("accessToken", response.data.accessToken);
//           return of(adminLoginSuccess(response.data));
//         }),
//         catchError((error) => of(adminLoginFailure(error)))
//       )
//     )
//   );

export const authenticateAdminEpic = (action$) =>
  action$.pipe(
    ofType("ADMIN_AUTH_REQUEST"),
    switchMap((action) =>
      from(API.loginApi(action.payload.adminCredential)).pipe(
        mergeMap((response) => {
          setCookie("accessToken", response.data.accessToken);
          console.log("response", response);
          return of({
            type: "ADMIN_AUTH_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADMIN_AUTH_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//admin profile
export const getAdminProfileEpic = (action$) =>
  action$.pipe(
    ofType("GET_ADMIN_PROFILE_REQUEST"),
    switchMap((action) =>
      from(API.getAdminProfile(null)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_ADMIN_PROFILE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "GET_ADMIN_PROFILE_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//change password
export const changeAdminPasswordEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_ADMIN_PASSWORD_REQUEST"),
    switchMap((action) =>
      from(API.changeAdminPassword(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "CHANGE_ADMIN_PASSWORD_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "CHANGE_ADMIN_PASSWORD_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//update admin profile

export const updateAdminProfileEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_ADMIN_PROFILE_REQUEST"),
    switchMap((action) =>
      from(API.updateAdminProfile(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "UPDATE_ADMIN_PROFILE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_ADMIN_PROFILE_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//get all privacy policy
export const privacyPolicyEpic = (action$) =>
  action$.pipe(
    ofType("PRIVACY_POLICY_REQUEST"),
    switchMap((action) =>
      from(API.privacyPolicy()).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: PRIVACY_POLICY_REQUEST_SUCCESS,
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: PRIVACY_POLICY_REQUEST_FAILURE,
            payload: err,
          });
        })
      )
    )
  );

//update privacy policy
export const privacyPolicyUpdateEpic = (action$) =>
  action$.pipe(
    ofType("PRIVACY_POLICY_UPDATE_REQUEST"),
    switchMap((action) =>
      from(API.privacyPolicyUpdate(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response from PP success ", response);
          return of({
            type: "PRIVACY_POLICY_UPDATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "PRIVACY_POLICY_UPDATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//get all terms and conditions
export const termsAndConditionsEpic = (action$) =>
  action$.pipe(
    ofType("TERMS_AND_CONDITIONS_REQUEST"),
    switchMap((action) =>
      from(API.termsAndConditions()).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "TERMS_AND_CONDITIONS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "TERMS_AND_CONDITIONS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update terms and conditions
export const termsAndConditionsUpdateEpic = (action$) =>
  action$.pipe(
    ofType("TERMS_AND_CONDITIONS_UPDATE_REQUEST"),
    switchMap((action) =>
      from(API.termsAndConditionsUpdate(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "TERMS_AND_CONDITIONS_UPDATE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "TERMS_AND_CONDITIONS_UPDATE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// getJobCategories
export const getJobCategoriesEpic = (action$) =>
  action$.pipe(
    ofType(
      "GET_JOB_CATEGORIES_REQUEST",
      "TOGGLE_JOB_STATUS_REQUEST_SUCCESS",
      "UPDATE_JOB_REQUEST_SUCCESS",
      "DELETE_JOB_CATEGORY_REQUEST_SUCCESS",
      "ADD_JOB_CATEGORIES_REQUEST_SUCCESS",
      "UPDATE_JOB_REQUEST_FAILURE",
      "GET_JOB_CATEGORIES_REQUEST_FAILURE"
    ),
    switchMap((action) =>
      from(API.getJobCategories()).pipe(
        mergeMap((response) => {
          console.log("response of jobCategories", response);
          return of({
            type: "GET_JOB_CATEGORIES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "GET_JOB_CATEGORIES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//add job categories
export const addJobCategoriesEpic = (action$) =>
  action$.pipe(
    ofType("ADD_JOB_CATEGORIES_REQUEST"),
    switchMap((action) =>
      from(API.addJobCategories(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "ADD_JOB_CATEGORIES_REQUEST_SUCCESS",
            payload: response,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_JOB_CATEGORIES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// delete job category
export const deleteJobCategoryEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_JOB_CATEGORY_REQUEST"),
    switchMap((action) =>
      from(API.deleteJobCategory(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "DELETE_JOB_CATEGORY_REQUEST_SUCCESS",
            payload: response,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "DELETE_JOB_CATEGORY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

// toggle job status
export const toggleJobStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_JOB_STATUS_REQUEST"),
    switchMap((action) =>
      from(API.toggleJobStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "TOGGLE_JOB_STATUS_REQUEST_SUCCESS",
            payload: response,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "TOGGLE_JOB_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update job
export const updateJobEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_JOB_REQUEST"),
    switchMap((action) =>
      from(API.updateJob(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "UPDATE_JOB_REQUEST_SUCCESS",
            payload: response,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_JOB_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//Get image url

export const getImageUrlEpic = (action$) =>
  action$.pipe(
    ofType("GET_IMAGE_URL_REQUEST"),
    switchMap((action) =>
      from(API.getImageUrl(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);

          return of({
            type: "GET_IMAGE_URL_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "GET_IMAGE_URL_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//subCategories
//get subcategories
export const getSubCategories = (action$) =>
  action$.pipe(
    ofType(
      "GET_SUBCATEGORIES_REQUEST",
      "TOGGLE_SUBCATEGORY_REQUEST_SUCCESS",
      "DELETE_SUBCATEGORY_REQUEST_SUCCESS",
      "ADD_JOB_SUB_CATEGORY_SUCCESS",
      "ADD_JOB_SUB_CATEGORY_FAILURE",
      "DELETE_SUBCATEGORY_REQUEST_FAILURE",
      "EDIT_JOB_SUBCATEGORY_SUCCESS",
      "EDIT_JOB_SUBCATEGORY_FAILURE"
    ),
    switchMap((action) =>
      from(API.getSubCategories(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_SUBCATEGORIES_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "GET_SUBCATEGORIES_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//toggle subcategories
export const toggleSubCategoriesStatus = (action$) =>
  action$.pipe(
    ofType("TOGGLE_SUBCATEGORY_REQUEST"),
    switchMap((action) =>
      from(API.toggleSubCategories(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "TOGGLE_SUBCATEGORY_REQUEST_SUCCESS",
            payload: action.payload.catId,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "TOGGLE_SUBCATEGORY_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//delete subCategories

export const deleteSubCategories = (action$) =>
  action$.pipe(
    ofType("DELETE_SUBCATEGORY_REQUEST"),
    switchMap((action) =>
      from(API.deleteSubCategories(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "DELETE_SUBCATEGORY_REQUEST_SUCCESS",
            payload: action.payload.catId,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "DELETE_SUBCATEGORY_REQUEST_FAILURE",
            payload: action.payload.catId,
          });
        })
      )
    )
  );

//Edit job subcategory
export const editSubCategoryEpic = (action$) =>
  action$.pipe(
    ofType("EDIT_JOB_SUBCATEGORY"),
    switchMap((action) =>
      from(API.editSubCategory(action.id, action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);

          return of({
            type: "EDIT_JOB_SUBCATEGORY_SUCCESS",
            payload: action.catId,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "EDIT_JOB_SUBCATEGORY_FAILURE",
            payload: action.catId,
          });
        })
      )
    )
  );

//add job subcategories

export const AddJobSubCategoriesEpic = (action$) =>
  action$.pipe(
    ofType("ADD_JOB_SUB_CATEGORY_REQUEST"),
    switchMap((action) =>
      from(API.AddJobSubCategories(action.id, action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "ADD_JOB_SUB_CATEGORY_SUCCESS",
            payload: action.id,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_JOB_SUB_CATEGORY_FAILURE",
            payload: action.id,
            payload2: err.response.data.message,
          });
        })
      )
    )
  );

//usermanagement (intern and recruiter)

//get intern
export const getInternDetailsEpic = (action$) =>
  action$.pipe(
    ofType(
      "GET_INTERN_DETAILS_REQUEST",
      "TOGGLE_INTERN_STATUS_REQUEST_SUCCESS",
      "DELETE_INTERN_REQUEST_SUCCESS"
    ),
    switchMap((action) =>
      from(API.getInternDetails()).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_INTERN_DETAILS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "GET_INTERN_DETAILS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//toggle intern status

export const toggleInternStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_INTERN_STATUS_REQUEST"),
    switchMap((action) =>
      from(API.toggleInternStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "TOGGLE_INTERN_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "TOGGLE_INTERN_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//delete intern

export const deleteInternEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_INTERN_REQUEST"),
    switchMap((action) =>
      from(API.deleteIntern(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "DELETE_INTERN_REQUEST_SUCCESS",
            payload: response,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "DELETE_INTERN_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//Recruiter
//get recruiter details
export const getRecruiterDetailsEpic = (action$) =>
  action$.pipe(
    ofType(
      "GET_RECRUITER_DETAILS_REQUEST",
      "TOGGLE_RECRUITER_STATUS_REQUEST_SUCCESS",
      "DELETE_RECRUITER_REQUEST_SUCCESS",
      "DELETE_RECRUITER_REQUEST_FAILURE"
    ),
    switchMap((action) =>
      from(API.getRecruiterDetails()).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_RECRUITER_DETAILS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "GET_RECRUITER_DETAILS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//toggle recruiter status
export const toggleRecruiterStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_RECRUITER_STATUS_REQUEST"),
    switchMap((action) =>
      from(API.toggleRecruiterStatus(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "TOGGLE_RECRUITER_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "TOGGLE_RECRUITER_STATUS_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//delete recruiter
export const deleteRecruiterEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_RECRUITER_REQUEST"),
    switchMap((action) =>
      from(API.deleteRecruiter(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "DELETE_RECRUITER_REQUEST_SUCCESS",
            payload: response,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "DELETE_RECRUITER_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//Support management

//get support details
export const getSupportDetailsEpic = (action$) =>
  action$.pipe(
    ofType(
      "GET_SUPPORT_DETAILS_REQUEST",
      "CHANGE_SUPPORT_STATUS_AS_SOLVED_REQUEST_SUCCESS",
      "CHANGE_SUPPORT_STATUS_AS_SOLVED_REQUEST_FAILURE"
    ),
    switchMap((action) =>
      from(API.getSupportDetails(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_SUPPORT_DETAILS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err.response.data);
          return of({
            type: "GET_SUPPORT_DETAILS_REQUEST_FAILURE",
            payload: err.response,
          });
        })
      )
    )
  );

//change support status as solved.

export const changeSupportStatusAsSolvedEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_SUPPORT_STATUS_AS_SOLVED_REQUEST"),
    switchMap((action) =>
      from(API.changeSupportStatusAsSolved(action)).pipe(
        mergeMap((response) => {
          console.log("response", response);

          // userType
          //upportStatus
          return of({
            type: "CHANGE_SUPPORT_STATUS_AS_SOLVED_REQUEST_SUCCESS",
            payload: {
              userType: action.payload.userType,
              supportStatus: action.payload.supportStatus,
            },
            payload2: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "CHANGE_SUPPORT_STATUS_AS_SOLVED_REQUEST_FAILURE",
            payload: {
              userType: action.payload.userType,
              supportStatus: action.payload.supportStatus,
            },
            payload2: err.response.data,
          });
        })
      )
    )
  );

//Advertisement
//get advertisement list
export const getAdvertisementListEpic = (action$) =>
  action$.pipe(
    ofType(
      "GET_ADVERISEMENT_LIST_REQUEST",
      "TOGGLE_ADVERTISEMENT_STATUS_REQUEST_SUCCESS",
      "TOGGLE_ADVERTISEMENT_STATUS_REQUEST_FAILURE",
      "ADD_NEW_ADVERTISEMENT_REQUEST_SUCCESS",
      "GET_ADVERISEMENT_LIST_REQUEST_FAILURE",
      "DELETE_ADVERTISEMENT_REQUEST_SUCCESS",
      "DELETE_ADVERTISEMENT_REQUEST_FAILURE",
      "UPDATE_ADVERTISEMENT_REQUEST_SUCCESS",
      "UPDATE_ADVERTISEMENT_REQUEST_SUCCESS"
    ),
    switchMap((action) =>
      from(API.getAdvertisementList()).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_ADVERISEMENT_LIST_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err.message);
          return of({
            type: "GET_ADVERISEMENT_LIST_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//Adde new advertisement
export const addNewAdvertisementEpic = (action$) =>
  action$.pipe(
    ofType("ADD_NEW_ADVERTISEMENT_REQUEST"),
    switchMap((action) =>
      from(API.addNewAdvertisement(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "ADD_NEW_ADVERTISEMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_NEW_ADVERTISEMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//update advertisement
export const updateAdvertisementEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_ADVERTISEMENT_REQUEST"),
    switchMap((action) =>
      from(API.updateAdvertisement(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "UPDATE_ADVERTISEMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_ADVERTISEMENT_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//delete advertisement

export const deleteAdvertisementEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_ADVERTISEMENT_REQUEST"),
    switchMap((action) =>
      from(API.deleteAdvertisement(action.id)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "DELETE_ADVERTISEMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_ADVERTISEMENT_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//toggle advertisement status
export const toggleAdvertisementStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_ADVERTISEMENT_STATUS_REQUEST"),
    switchMap((action) =>
      from(API.toggleAdvertisementStatus(action.id)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "TOGGLE_ADVERTISEMENT_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "TOGGLE_ADVERTISEMENT_STATUS_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//Recruiter post fee

//get recruiter post fee

export const getRecruiterPostFeeEpic = (action$) =>
  action$.pipe(
    ofType(
      "GET_RECRUITER_POST_FEE_REQUEST",
      "DELETE_RECRUITER_POST_FEE_REQUEST_SUCCESS",
      "DELETE_RECRUITER_POST_FEE_REQUEST_FAILURE",
      "ADD_RECRUITER_POST_FEE_REQUEST_SUCCESS",
      "ADD_RECRUITER_POST_FEE_REQUEST_FAILURE",
      "UPDATE_POST_FEE_REQUEST_SUCCESS",
      "UPDATE_POST_FEE_REQUEST_FAILURE",
      "TOGGLE_RECRUITER_POST_FEE_STATUS_REQUEST_SUCCESS",
      "TOGGLE_RECRUITER_POST_FEE_STATUS_REQUEST_FAILURE"
    ),
    switchMap((action) =>
      from(API.getRecruiterPostFee()).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_RECRUITER_POST_FEE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "GET_RECRUITER_POST_FEE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//delete recruiter post fee

export const deleteRecruiterPostFeeEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_RECRUITER_POST_FEE_REQUEST"),
    switchMap((action) =>
      from(API.deleteRecruiterPostFee(action.id)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "DELETE_RECRUITER_POST_FEE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_RECRUITER_POST_FEE_REQUEST_FAILURE",
            payload: err,
          });
        })
      )
    )
  );

//add recruiter post fee
export const addRecruiterPostFeeEpic = (action$) =>
  action$.pipe(
    ofType("ADD_RECRUITER_POST_FEE_REQUEST"),
    switchMap((action) =>
      from(API.addRecruiterPostFee(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "ADD_RECRUITER_POST_FEE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_RECRUITER_POST_FEE_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//update recruiter post fee
export const updateRecruiterPostFeeEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_POST_FEE_REQUEST"),
    switchMap((action) =>
      from(API.updateRecruiterPostFee(action)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "UPDATE_POST_FEE_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_POST_FEE_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//get package days
export const getPackageDaysEpic = (action$) =>
  action$.pipe(
    ofType(
      "GET_PACKAGE_DAYS_REQUEST",
      "GET_RECRUITER_POST_FEE_BY_PACKAGE_DAYS_REQUEST_SUCCESS",
      "TOGGLE_RECRUITER_POST_FEE_STATUS_REQUEST_SUCCESS",
      "TOGGLE_RECRUITER_POST_FEE_STATUS_REQUEST_FAILURE",
      "ADD_RECRUITER_POST_FEE_REQUEST_SUCCESS",
      "ADD_RECRUITER_POST_FEE_REQUEST_FAILURE",
      "UPDATE_POST_FEE_REQUEST_SUCCESS",
      "UPDATE_POST_FEE_REQUEST_FAILURE",
      "DELETE_RECRUITER_POST_FEE_REQUEST_SUCCESS",
      "DELETE_RECRUITER_POST_FEE_REQUEST_FAILURE"
    ),
    switchMap((action) =>
      from(API.getPackageDays()).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_PACKAGE_DAYS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "GET_PACKAGE_DAYS_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//toggle recruiter fee status
export const toggleRecruiterFeeStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_RECRUITER_POST_FEE_STATUS_REQUEST"),
    switchMap((action) =>
      from(API.toggleRecruiterFeeStatus(action.id)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "TOGGLE_RECRUITER_POST_FEE_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "TOGGLE_RECRUITER_POST_FEE_STATUS_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//get recruiter post fee list by package days
export const getRecruiterFeeByPackageDaysEpic = (action$) =>
  action$.pipe(
    ofType("GET_RECRUITER_POST_FEE_BY_PACKAGE_DAYS_REQUEST"),
    switchMap((action) =>
      from(API.getRecruiterFeeByPackageDays(action.id)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_RECRUITER_POST_FEE_BY_PACKAGE_DAYS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "GET_RECRUITER_POST_FEE_BY_PACKAGE_DAYS_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//recruiter post
//get recruiter post by fee id

export const getRecruiterPostEpic = (action$) =>
  action$.pipe(
    ofType(
      "GET_RECRUITER_POST_REQUEST",
      "DELETE_RECRUITER_POST_REQUEST_SUCCESS",
      "DELETE_RECRUITER_POST_REQUEST_FAILURE"
    ),
    switchMap((action) =>
      from(API.getRecruiterPost(action.id)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_RECRUITER_POST_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "GET_RECRUITER_POST_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

export const deleteRecruiterPostEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_RECRUITER_POST_REQUEST"),
    switchMap((action) =>
      from(API.deleteRecruiterPost(action.id)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "DELETE_RECRUITER_POST_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_RECRUITER_POST_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//subscription plan

//Get subscription plan
export const getSubscriptionPlanEpic = (action$) =>
  action$.pipe(
    ofType(
      "GET_SUBSCRIPTION_PLAN_REQUEST",
      "DELETE_SUBSCRIPTION_PLAN_REQUEST_SUCCESS",
      "DELETE_SUBSCRIPTION_PLAN_REQUEST_FAILURE",
      "TOGGLE_SUBSCRIPTION_PLAN_STATUS_REQUEST_SUCCESS",
      "TOGGLE_SUBSCRIPTION_PLAN_STATUS_REQUEST_FAILURE",
      "ADD_NEW_SUBSCRIPTION_PLAN_REQUEST_SUCCESS",
      "ADD_NEW_SUBSCRIPTION_PLAN_REQUEST_FAILURE",
      "UPDATE_SUBSCRIPTION_PLAN_REQUEST_SUCCESS",
      "UPDATE_SUBSCRIPTION_PLAN_REQUEST_FAILURE"
    ),
    switchMap((action) =>
      from(API.getSubscriptionPlan()).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_SUBSCRIPTION_PLAN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "GET_SUBSCRIPTION_PLAN_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//Delete subscription plan

export const deleteSubscriptionPlanEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_SUBSCRIPTION_PLAN_REQUEST"),
    switchMap((action) =>
      from(API.deleteSubscriptionPlan(action.id)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "DELETE_SUBSCRIPTION_PLAN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "DELETE_SUBSCRIPTION_PLAN_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//toggle subscription plan

export const toggleSubscriptionPlanStatusEpic = (action$) =>
  action$.pipe(
    ofType("TOGGLE_SUBSCRIPTION_PLAN_STATUS_REQUEST"),
    switchMap((action) =>
      from(API.toggleSubscriptionPlanStatus(action.id)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "TOGGLE_SUBSCRIPTION_PLAN_STATUS_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "TOGGLE_SUBSCRIPTION_PLAN_STATUS_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//add new subscription plan

export const addNewSubscriptionPlanEpic = (action$) =>
  action$.pipe(
    ofType("ADD_NEW_SUBSCRIPTION_PLAN_REQUEST"),
    switchMap((action) =>
      from(API.addNewSubscriptionPlan(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "ADD_NEW_SUBSCRIPTION_PLAN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_NEW_SUBSCRIPTION_PLAN_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//update/edit subscription plan

export const updateSubscriptionPlanEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_SUBSCRIPTION_PLAN_REQUEST"),
    switchMap((action) =>
      from(API.updateSubscriptionPlan(action)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "UPDATE_SUBSCRIPTION_PLAN_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "UPDATE_SUBSCRIPTION_PLAN_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//payment management
//get payment list

export const getPaymentListEpic = (action$) =>
  action$.pipe(
    ofType(
      "GET_PAYMENT_LIST_REQUEST",
      "APPROVE_PAYMENT_REQUEST_SUCCESS",
      "APPROVE_PAYMENT_REQUEST_FAILURE",
      "REJECT_PAYMENT_REQUEST_SUCCESS",
      "REJECT_PAYMENT_REQUEST_FAILURE"
    ),
    switchMap((action) =>
      from(API.getPaymentList()).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_PAYMENT_LIST_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "GET_PAYMENT_LIST_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//approve payment

export const approvePaymentEpic = (action$) =>
  action$.pipe(
    ofType("APPROVE_PAYMENT_REQUEST"),
    switchMap((action) =>
      from(API.approvePayment(action.id)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "APPROVE_PAYMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "APPROVE_PAYMENT_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//reject payment
export const rejectPaymentEpic = (action$) =>
  action$.pipe(
    ofType("REJECT_PAYMENT_REQUEST"),
    switchMap((action) =>
      from(API.rejectPayment(action.id)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "REJECT_PAYMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "REJECT_PAYMENT_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//subscription plan payment
export const getSubscriptionPlanPaymentListEpic = (action$) =>
  action$.pipe(
    ofType(
      "GET_SUBSCRIPTION_PLAN_PAYMENT_LIST_REQUEST",
      "APPROVE_SUBSCRIPTION_PLAN_PAYMENT_REQUEST_SUCCESS",
      "APPROVE_SUBSCRIPTION_PLAN_PAYMENT_REQUEST_FAILURE",
      "REJECT_SUBSCRIPTION_PLAN_PAYMENT_REQUEST_SUCCESS",
      "REJECT_SUBSCRIPTION_PLAN_PAYMENT_REQUEST_FAILURE"
    ),
    switchMap((action) =>
      from(API.getSubscriptionPlanPaymentList()).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_SUBSCRIPTION_PLAN_PAYMENT_LIST_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "GET_SUBSCRIPTION_PLAN_PAYMENT_LIST_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

export const approveSubscriptionPlanPaymentEpic = (action$) =>
  action$.pipe(
    ofType("APPROVE_SUBSCRIPTION_PLAN_PAYMENT_REQUEST"),
    switchMap((action) =>
      from(API.approveSubscriptionPlanPayment(action.id)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "APPROVE_SUBSCRIPTION_PLAN_PAYMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "APPROVE_SUBSCRIPTION_PLAN_PAYMENT_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

export const rejectSubscriptionPlanPaymentEpic = (action$) =>
  action$.pipe(
    ofType("REJECT_SUBSCRIPTION_PLAN_PAYMENT_REQUEST"),
    switchMap((action) =>
      from(API.rejectSubscriptionPlanPayment(action.id)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "REJECT_SUBSCRIPTION_PLAN_PAYMENT_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "REJECT_SUBSCRIPTION_PLAN_PAYMENT_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//dashboard data
export const getDashboardDataEpic = (action$) =>
  action$.pipe(
    ofType("GET_DASHBOARD_DATA_REQUEST"),
    switchMap((action) =>
      from(API.getDashboardData()).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "GET_DASHBOARD_DATA_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "GET_DASHBOARD_DATA_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );

//add mass notification
export const addMassNotificationEpic = (action$) =>
  action$.pipe(
    ofType("ADD_MASS_NOTIFICATION_REQUEST"),
    switchMap((action) =>
      from(API.addMassNotification(action.payload)).pipe(
        mergeMap((response) => {
          console.log("response", response);
          return of({
            type: "ADD_MASS_NOTIFICATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((err) => {
          console.warn(err);
          return of({
            type: "ADD_MASS_NOTIFICATION_REQUEST_FAILURE",
            payload: err.response.data,
          });
        })
      )
    )
  );
