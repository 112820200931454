import { Button, Form, Input, Select, Spin } from "antd";
import React from "react";
import Model from "../UI/Model/Model";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
const { Option } = Select;
const { TextArea } = Input;

const MassNotification = () => {
  const massNotificationState = useSelector(
    (state) => state.MassNotificationReducer
  );
  const [form] = useForm();
  const dispatch = useDispatch();
  return (
    <Model>
      <Spin spinning={massNotificationState.isLoading}></Spin>
      <div
        style={{
          margin: "0 10%",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          padding: "1rem 2rem",
        }}
      >
        <p
          style={{
            backgroundColor: "blue",
            padding: "1rem",
            color: "white",
            fontWeight: "600",
          }}
        >
          Mass Notification
        </p>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            dispatch({
              type: "ADD_MASS_NOTIFICATION_REQUEST",
              payload: values,
            });
            form.resetFields();
          }}
        >
          <Form.Item
            label="Notification Title"
            name={"title"}
            rules={[
              { required: true, message: "Please enter notification title!" },
            ]}
          >
            <Input name="title" placeholder="Notification Title" />
          </Form.Item>
          <Form.Item
            label="User Type"
            name={"userType"}
            rules={[{ required: true, message: "Please enter user type!" }]}
          >
            <Select>
              <Option value="INTERN">Intern</Option>
              <Option value="RECRUITER">Recruiter</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Notification Body"
            name={"body"}
            rules={[
              { required: true, message: "Please enter notification body!" },
            ]}
          >
            <TextArea name="body" placeholder="Notification body" rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" block htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Model>
  );
};

export default MassNotification;
