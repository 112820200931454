import React, { useEffect, useState } from "react";
import "./Advertisement.css";
import Model from "../UI/Model/Model";
import {
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import AddAdvertisement from "./AddAdvertisement/AddAdvertisement";
import {
  Drawer,
  Button,
  Table,
  Input,
  Tooltip,
  Space,
  Modal,
  Popconfirm,
  Image,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import EditAdvertisement from "./EditAdvertisement/EditAdvertisement";
import AdvertisementDetails from "./AdvertisementDetails/AdvertisementDetails";
import { MdDisabledVisible } from "react-icons/md";
import TableSkeleton from "../UI/TableSkeleton/tableSkelaton";
const { Search } = Input;

const columns = [
  {
    title: "Advertisement Name",
    dataIndex: "advertisementName",
  },
  {
    title: "Company Name",
    dataIndex: "companyName",
  },
  {
    title: "Company URL",
    dataIndex: "companyURL",
  },
  {
    title: "User Type",
    dataIndex: "userType",
  },
  {
    title: "Image",
    dataIndex: "advertisementURL",
  },
  {
    title: "Status",
    dataIndex: "disabled",
  },
  {
    title: "Actions",
    dataIndex: "Actions",
  },
];

const Advertisement = () => {
  const advertisementState = useSelector((state) => state.AdvertisementReducer);
  const dispatch = useDispatch();
  const [isAdvertisementDetailsVisible, setIsAdvertisementDetailsVisible] =
    useState(false);
  const [
    isEditAdvertisementDrawerVisible,
    setIsEditAdvertisementDrawerVisible,
  ] = useState(false);
  const [isAddAdvertisementDrawerVisible, setIsAddAdvertisementDrawerVisible] =
    useState(false);
  const [record, setRecord] = useState();
  useEffect(() => {
    dispatch({
      type: "GET_ADVERISEMENT_LIST_REQUEST",
    });
  }, [isAddAdvertisementDrawerVisible, isEditAdvertisementDrawerVisible]);
  const data = advertisementState?.advertisementList?.map((dataObj) => {
    return {
      key: dataObj.id,
      advertisementName: dataObj.advertisementName,
      companyName: dataObj.companyName,
      companyURL: dataObj.companyURL,
      userType: dataObj.userType,
      disabled: dataObj.disabled ? (
        <p
          style={{
            display: "inline",
            padding: "2px 3px",
            border: "1px solid red",
            textAlign: "center",
            color: "red",
            borderRadius: "3px",
          }}
        >
          Disabled
        </p>
      ) : (
        <p
          style={{
            display: "inline",
            padding: "2px 3px",
            border: "1px solid green",
            textAlign: "center",
            color: "green",
            borderRadius: "3px",
          }}
        >
          Active
        </p>
      ),
      advertisementURL: dataObj.advertisementURL ? (
        <Image
          src={dataObj.advertisementURL}
          style={{ height: "50px", width: "50px" }}
        />
      ) : (
        ""
      ),
      Actions: (
        <Space size="middle">
          <Tooltip title="Edit Advertisement">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsEditAdvertisementDrawerVisible(dataObj);

                  //    console.log(dataObj);
                  console.log(
                    dataObj,
                    "the value from the datapobj inside drawer"
                  );

                  setRecord(dataObj);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete Advertisement">
            <Popconfirm
              title="Are you sure to delete this advertisement?"
              onConfirm={() => {
                dispatch({
                  type: "DELETE_ADVERTISEMENT_REQUEST",
                  id: dataObj.id,
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined />
                </div>
              </a>{" "}
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Show Details">
            <a
              onClick={() => {
                setIsAdvertisementDetailsVisible(true);
                setRecord(dataObj);
              }}
            >
              <div className="bordered">
                <EyeOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Enable/Disable Advertisement">
            <a
              onClick={() => {
                dispatch({
                  type: "TOGGLE_ADVERTISEMENT_STATUS_REQUEST",
                  id: dataObj.id,
                });
              }}
            >
              <div className="bordered">
                <MdDisabledVisible />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  console.log("advertisement list", advertisementState.advertisementList);
  return (
    <Model>
      <div>
        <div className="advertiseHeader">
          <div className="headerAndButton">
            <h3>Advertisement</h3>
            <Button
              type="primary"
              onClick={() => {
                setIsAddAdvertisementDrawerVisible(true);
              }}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
            <Drawer
              title="Add Advertisement"
              placement="right"
              visible={isAddAdvertisementDrawerVisible}
              onClose={() => {
                setIsAddAdvertisementDrawerVisible(false);
              }}
              width={1150}
              footer={null}
            >
              <AddAdvertisement
                onHideAddAdvertisementDrawer={() =>
                  setIsAddAdvertisementDrawerVisible(false)
                }
              />
            </Drawer>
          </div>
          {/* <Search
            size="large"
            width={10}
            placeholder="Search for advertisements"
            enterButton
          /> */}
        </div>

        <div>
          {advertisementState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={data}
              style={{ marginTop: "1.5rem" }}
            />
          )}
        </div>
        <Modal
          title="Advertisement details"
          visible={isAdvertisementDetailsVisible}
          onCancel={() => {
            setIsAdvertisementDetailsVisible(false);
          }}
          footer={null}
        >
          <AdvertisementDetails record={record} />
        </Modal>

        {console.log(record, "the value from the record inside drawer")}
        <EditAdvertisement
          record={isEditAdvertisementDrawerVisible}
          onHideEditAdvertisementDrawer={() =>
            setIsEditAdvertisementDrawerVisible(false)
          }
          isEditAdvertisementDrawerVisible={isEditAdvertisementDrawerVisible}
          setIsEditAdvertisementDrawerVisible={
            setIsEditAdvertisementDrawerVisible
          }
        />
      </div>
    </Model>
  );
};

export default Advertisement;

//   const [
//     isEditAdvertisementDrawerVisible,
//     setIsEditAdvertisementDrawerVisible,
//   ] = useState(false);
