import React, { useState } from "react";
import { Button, Drawer, Form, Input, Select, DatePicker } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
const { Option } = Select;
const UpdateAdminProfile = (props) => {
  const dispatch = useDispatch();
  const [dobDate, setDobDate] = useState();
  const [form] = Form.useForm();
  const submitUpdateAdminProfile = (value) => {
    const payload = {
      id: props.record.id,
      ...value,
      dateOfBirth: dobDate,
    };
    dispatch({ type: "UPDATE_ADMIN_PROFILE_REQUEST", payload });
    console.log(payload);
    form.resetFields();
    props.onHide();
  };

  return (
    <>
      <Form
        fields={[
          {
            name: ["fullName"],
            value: props.record.fullName,
          },
          {
            name: ["mobileNumber"],
            value: props.record.phoneNo,
          },
          {
            name: ["gender"],
            value: props.record.gender,
          },
          {
            name: ["fullName"],
            value: props.record.fullName,
          },
        ]}
        labelCol={{ span: 5, offset: 0 }}
        wrapperCol={{ span: 24, offset: 0 }}
        style={{
          margin: "0 20%",
        }}
        onFinish={submitUpdateAdminProfile}
        form={form}
      >
        <Form.Item label="Full Name" name="fullName">
          <Input placeholder="Full Name" />
        </Form.Item>
        <Form.Item label="Mobile Number" name="mobileNumber">
          <Input placeholder="Mobile Number" />
        </Form.Item>
        <Form.Item label="Date of Birth" name="dateOfBirth">
          {/*  */}
          <DatePicker
            disabledDate={(currentDate) => {
              //   const today = new Date();
              //   console.log("this is ", moment(today));
              //   return moment() > moment(today);
            }}
            onChange={(e) => setDobDate(moment(e).format("DD/MM/YYYY"))}
          />
        </Form.Item>
        <Form.Item label="Gender" name="gender">
          <Select>
            <Option value="Male">Male</Option>
            <Option value="Female">Female</Option>
            <Option value="Others">Others</Option>
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 5 }}>
          <Button type="primary" block htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UpdateAdminProfile;
