import React, { Fragment } from "react";
import { Card, Button } from "antd";
import "react-quill/dist/quill.snow.css";
import "./TermsModel.css";
import Model from "../Model/Model";
import { useSelector } from "react-redux";
const TermsModel = (props) => {
  const PrivacyAndTermsState = useSelector(
    (state) => state.PrivacyAndTermsReducer
  );
  return (
    <Fragment>
      <Model>
        <Card loading={PrivacyAndTermsState.isLoading}>
          <div className="termsHeader">{props.headerName}</div>
          {props.children}
          <div className="termsbutton">
            <Button type="primary" onClick={props.onClickHandler}>
              {props.buttonName}
            </Button>
          </div>
        </Card>
      </Model>
    </Fragment>
  );
};

export default TermsModel;
