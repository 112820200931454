import React from "react";
import Model from "../UI/Model/Model";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Card from "../Card/Card";
import UpdateAdminProfile from "./UpdateAdminProfile";
import moment from "moment";
import { Button, Drawer, Spin } from "antd";
import { useState } from "react";

const AdminProfile = () => {
  const profileState = useSelector((state) => state.AdminProfileReducer);
  const dispatch = useDispatch();
  const [isUpdateProfileDrawerVisible, setIsUpdateProfileDrawerVisible] =
    useState(false);
  useEffect(() => {
    if (isUpdateProfileDrawerVisible === false) {
      dispatch({
        type: "GET_ADMIN_PROFILE_REQUEST",
      });
    }
  }, [isUpdateProfileDrawerVisible]);
  console.log(profileState.profile);
  return (
    <Model>
      <Spin spinning={profileState.isLoading}>
        <div
          style={{
            margin: "0 30%",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            padding: "1rem 2rem",
          }}
        >
          <p
            style={{
              backgroundColor: "blue",
              padding: "1rem",
              color: "white",
              fontWeight: "600",
            }}
          >
            Admin Profile
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0.5rem",
              padding: "0 0rem",
            }}
          >
            Full Name: <span>{profileState.profile.fullName}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0.5rem",
              padding: "0 0rem",
            }}
          >
            User Name: <span>{profileState.profile.userName}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0.5rem",
              padding: "0 0rem",
            }}
          >
            Email: <span>{profileState.profile.email}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0.5rem",
              padding: "0 0rem",
            }}
          >
            Phone No: <span>{profileState.profile.phoneNo}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0.5rem",
              padding: "0 0rem",
            }}
          >
            Gender: <span>{profileState.profile.gender}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0.5rem",
              padding: "0 0rem",
            }}
          >
            Date of Birth:{" "}
            <span>
              {profileState.profile.dob
                ? moment(profileState.profile.dob).format("YYYY-MM-DD")
                : ""}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0.5rem",
              padding: "0 0rem",
            }}
          >
            Status:{" "}
            <span>
              {profileState.profile.disabled ? (
                <p
                  style={{
                    display: "inline",
                    padding: "2px 3px",
                    border: "1px solid red",
                    textAlign: "center",
                    color: "red",
                    borderRadius: "3px",
                  }}
                >
                  Disabled
                </p>
              ) : (
                <p
                  style={{
                    display: "inline",
                    padding: "2px 3px",
                    border: "1px solid green",
                    textAlign: "center",
                    color: "green",
                    borderRadius: "3px",
                  }}
                >
                  Active
                </p>
              )}
            </span>
          </div>
          <Button
            block
            type="primary"
            onClick={() => {
              setIsUpdateProfileDrawerVisible(true);
            }}
          >
            Update
          </Button>
          <Drawer
            title={"Update Profile"}
            placement="right"
            onClose={() => {
              setIsUpdateProfileDrawerVisible(false);
            }}
            visible={isUpdateProfileDrawerVisible}
            width={1150}
          >
            <UpdateAdminProfile
              record={profileState.profile}
              onHide={() => setIsUpdateProfileDrawerVisible(false)}
            />
          </Drawer>
        </div>
      </Spin>
    </Model>
  );
};

export default AdminProfile;
