import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Modal, Popconfirm, Space, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MdDisabledVisible } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";
import TableSkeleton from "../UI/TableSkeleton/tableSkelaton";
import AddJobSubCategory from "./UpdateJobModel/AddJobSubCategory";
import EditSubCategory from "./UpdateJobModel/EditSubCategory/EditSubCategory";
const SubCategories = (props) => {
  const { record } = props;
  const subCategoryState = useSelector(
    (state) => state.InternshipCategoriesReducer
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [dataObject, setDataObject] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "GET_SUBCATEGORIES_REQUEST",
      payload: props.record.jobCategoryId,
    });
  }, [props.isSubCategoriesDrawerVisible]);

  const data =
    subCategoryState.jobSubCategories &&
    subCategoryState.jobSubCategories?.map((dataObj) => {
      return {
        key: dataObj.subCategoryId,
        subCategoryName: dataObj.subCategoryName,
        addedDate: dataObj.addedDate
          ? moment(dataObj.addedDate).format("YYYY-DD-MM")
          : "",
        addedBy: dataObj.addedBy,
        updatedDate: dataObj.updatedDate
          ? moment(dataObj.updatedDate).format("YYYY-DD-MM")
          : "",
        updatedBy: dataObj.updatedBy,
        disable: dataObj.disable ? (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid red",
              textAlign: "center",
              color: "red",
              borderRadius: "3px",
            }}
          >
            {" "}
            Disabled
          </p>
        ) : (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid green",
              textAlign: "center",
              color: "green",
              borderRadius: "3px",
            }}
          >
            Active
          </p>
        ),
        actions: (
          <Space size="middle">
            <Tooltip title="Edit Job">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsModalVisible(true);
                    setDataObject(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Delete Job">
              <Popconfirm
                title="Are you sure to delete this job sub category?"
                onConfirm={() => {
                  deleteJob(dataObj.subCategoryId);
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Disable/Enable Job">
              <a onClick={() => toggleStatus(dataObj.subCategoryId)}>
                <div className="bordered">
                  <MdDisabledVisible />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
      };
    });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showAddModal = () => {
    setIsAddModalVisible(true);
  };

  const handleAddOk = () => {
    setIsAddModalVisible(false);
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };

  const columns = [
    {
      title: "Sub-Category",
      dataIndex: "subCategoryName",
    },
    {
      title: "Added Date",
      dataIndex: "addedDate",
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
    },
    {
      title: "Updated Date",
      dataIndex: "updatedDate",
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
    },
    {
      title: "Status",
      dataIndex: "disable",
    },
    {
      title: "Actions",
      dataIndex: "actions",
    },
  ];

  const deleteJob = (subCatId) => {
    const payload = {
      subCatId,
      catId: record.jobCategoryId,
    };
    dispatch({
      type: "DELETE_SUBCATEGORY_REQUEST",
      payload,
    });
  };

  const toggleStatus = (subCatId) => {
    const payload = {
      subCatId,
      catId: record.jobCategoryId,
    };

    dispatch({
      type: "TOGGLE_SUBCATEGORY_REQUEST",
      payload,
    });
  };
  return (
    <>
      <Card
        style={{
          marginTop: "35px",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
          padding: "1.5rem",
        }}
      >
        <Button
          type="primary"
          color="black"
          onClick={showAddModal}
          style={{
            float: "right",
            borderRadius: "50%",
            height: "50px",
            width: "50px",
            marginBottom: "4px",
            marginRight: "6px",
          }}
        >
          <div className="plusSign">
            <PlusOutlined size="32px" />
          </div>
        </Button>
        {subCategoryState.isLoading ? (
          <TableSkeleton />
        ) : (
          <Table bordered={true} columns={columns} dataSource={data} />
        )}
      </Card>
      <Modal
        title="Add job sub-category"
        visible={isAddModalVisible}
        onOk={handleAddOk}
        onCancel={handleAddCancel}
        footer={null}
      >
        <AddJobSubCategory
          onAddSubCategoryHandler={handleAddCancel}
          id={record.jobCategoryId}
        />
      </Modal>
      <Modal
        title="Edit job sub-category"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <EditSubCategory
          onModelCancel={handleCancel}
          record={record}
          dataObject={dataObject}
        />
      </Modal>
    </>
  );
};

export default SubCategories;
