import { message } from "antd";
const INIT_STATE = {
  recruiterPostPaymentList: [],
  isLoading: false,
  subscriptionPlanPaymentList: [],
};

const PaymentManagementReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "GET_PAYMENT_LIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_PAYMENT_LIST_REQUEST_SUCCESS": {
      return {
        ...state,
        recruiterPostPaymentList: action.payload,
        isLoading: false,
      };
    }
    case "GET_PAYMENT_LIST_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Unable to get payment list!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "APPROVE_PAYMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "APPROVE_PAYMENT_REQUEST_SUCCESS": {
      message.success("Payment approved");
      return {
        ...state,
        paymentList: action.payload,
        isLoading: false,
      };
    }
    case "APPROVE_PAYMENT_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Payment approval failed");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "REJECT_PAYMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "REJECT_PAYMENT_REQUEST_SUCCESS": {
      message.success("Payment rejected");
      return {
        ...state,
        paymentList: action.payload,
        isLoading: false,
      };
    }
    case "REJECT_PAYMENT_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Payment rejection failed");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "GET_SUBSCRIPTION_PLAN_PAYMENT_LIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_SUBSCRIPTION_PLAN_PAYMENT_LIST_REQUEST_SUCCESS": {
      return {
        ...state,
        subscriptionPlanPaymentList: action.payload,
        isLoading: false,
      };
    }
    case "GET_SUBSCRIPTION_PLAN_PAYMENT_LIST_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Unable to get payment list!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "APPROVE_SUBSCRIPTION_PLAN_PAYMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "APPROVE_SUBSCRIPTION_PLAN_PAYMENT_REQUEST_SUCCESS": {
      message.success("Payment approved");
      return {
        ...state,
        paymentList: action.payload,
        isLoading: false,
      };
    }
    case "APPROVE_SUBSCRIPTION_PLAN_PAYMENT_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Payment approval failed");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "REJECT_SUBSCRIPTION_PLAN_PAYMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "REJECT_SUBSCRIPTION_PLAN_PAYMENT_REQUEST_SUCCESS": {
      message.success("Payment rejected");
      return {
        ...state,
        paymentList: action.payload,
        isLoading: false,
      };
    }
    case "REJECT_SUBSCRIPTION_PLAN_PAYMENT_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Payment rejection failed");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return INIT_STATE;
  }
};

export default PaymentManagementReducer;
