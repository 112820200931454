import React from "react";
import "./AddJobSubCategory.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import { useDispatch } from "react-redux";

const AddJobSubCategory = ({ id, onAddSubCategoryHandler }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    dispatch({
      type: "ADD_JOB_SUB_CATEGORY_REQUEST",
      id: id,
      payload: values.users,
    });
    onAddSubCategoryHandler();
    form.resetFields();
    console.log("Received values of form:", values);
  };
  return (
    <>
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        wrapperCol={{ span: 24 }}
      >
        <Form.List name="users">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: "flex",
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "subCategoryName"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing job sub-category",
                      },
                    ]}
                  >
                    <Input placeholder="Job sub-category" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" block htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddJobSubCategory;
