import React, { useEffect, useState } from "react";
import { Space, Table, Tag, Tooltip, Drawer, Popconfirm } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { MdDisabledVisible } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import UserDetails from "../UserDetails/UserDetails";
import "./InternManagement.css";
import TableSkeleton from "../../UI/TableSkeleton/tableSkelaton";

const columns = [
  {
    title: "Intern Name",
    dataIndex: "fullName",
  },
  {
    title: "Gender",
    dataIndex: "gender",
  },
  {
    title: "Date of Birth",
    dataIndex: "dateOfBirth",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Phone",
    dataIndex: "phone",
  },
  {
    title: "Skills",
    dataIndex: "skills",
    width: 100,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "disabled",
  },
  {
    title: "Actions",
    dataIndex: "actions",
  },
];

const InternManagement = () => {
  const InternState = useSelector((state) => state.InternManagementReducer);
  const dispatch = useDispatch();
  const [isUserDetailsDrawerVisible, setIsUserDetailsDrawerVisible] =
    useState(false);
  const [detailsObj, setDetailsObj] = useState({});
  useEffect(() => {
    dispatch({ type: "GET_INTERN_DETAILS_REQUEST" });
  }, []);
  const data = InternState.internDetails?.data?.map((dataObj) => {
    return {
      key: dataObj.internId,
      fullName: dataObj.userDetails.fullName,
      gender: dataObj.userDetails.gender,
      dateOfBirth: dataObj.userDetails.dateOfBirth,
      email: dataObj.userDetails.email,
      phone: dataObj.userDetails.phone,
      skills: dataObj.skills.map((skill, index) => {
        return (
          <Tag color="blue" key={index} style={{ margin: "2px 3px" }}>
            {skill}
          </Tag>
        );
      }),
      disabled: dataObj.userDetails.disabled ? (
        <p
          style={{
            display: "inline",
            padding: "2px 3px",
            border: "1px solid red",
            textAlign: "center",
            color: "red",
            borderRadius: "3px",
          }}
        >
          Disabled
        </p>
      ) : (
        <p
          style={{
            display: "inline",
            padding: "2px 3px",
            border: "1px solid green",
            textAlign: "center",
            color: "green",
            borderRadius: "3px",
          }}
        >
          Active
        </p>
      ),
      actions: (
        <Space size="middle">
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this intern?"
              onConfirm={() => {
                deleteIntern(dataObj.internId);
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Show Details">
            <a onClick={() => showDrawer(dataObj)}>
              <div className="bordered">
                <EyeOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Disable/Enable Status">
            <a onClick={() => toggleStatus(dataObj.internId)}>
              <div className="bordered">
                <MdDisabledVisible />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  const deleteIntern = (id) => {
    dispatch({
      type: "DELETE_INTERN_REQUEST",
      payload: id,
    });
  };
  const showDrawer = (dataObj) => {
    setIsUserDetailsDrawerVisible(true);
    setDetailsObj(dataObj);
  };
  const handleEditCancel = () => {
    setIsUserDetailsDrawerVisible(false);
  };
  const toggleStatus = (id) => {
    dispatch({
      type: "TOGGLE_INTERN_STATUS_REQUEST",
      payload: id,
    });
  };

  console.log("from internstate", InternState);
  return (
    <>
      {InternState.isLoading ? (
        <TableSkeleton />
      ) : (
        <Table bordered={true} columns={columns} dataSource={data} />
      )}
      <Drawer
        title={detailsObj.userDetails?.fullName}
        placement="right"
        visible={isUserDetailsDrawerVisible}
        onClose={handleEditCancel}
        width={1150}
        footer={null}
      >
        <UserDetails userDetailsObj={detailsObj} />
      </Drawer>
    </>
  );
};

export default InternManagement;
