import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import LoginReducer from "../Reducers/Login/LoginReducer";
import InternshipCategoriesReducer from "../Reducers/InternShipCategories/InternshipCategoriesReducer";
import PrivacyAndTermsReducer from "../Reducers/PrivacyAndTerms/PrivacyAndTermsReducer";
import InternManagementReducer from "../Reducers/UserManagement/InternManagementReducer";
import RecruiterManagementReducer from "../Reducers/UserManagement/RecruiterManagementReducer";
import SupportReducer from "../Reducers/support/SupportReducer";
import AdvertisementReducer from "../Reducers/Advertisement/AdvertisementReducer";
import RecruiterPostFeeReducer from "../Reducers/RecruiterPostFee/RecruiterPostFeeReducer";
import SubscriptionPlanReducer from "../Reducers/SubscriptionPlan/SubscriptionPlanReducer";
import AdminProfileReducer from "../Reducers/Profile/AdminProfileReducer";
import PaymentManagementReducer from "../Reducers/PaymentManagement/PaymentManagementReducer";
import DashboardReducer from "../Reducers/Dashboard/DashboardReducer";
import MassNotificationReducer from "../Reducers/MassNotificationReducer";
import {
  authenticateAdminEpic,
  privacyPolicyEpic,
  privacyPolicyUpdateEpic,
  termsAndConditionsEpic,
  termsAndConditionsUpdateEpic,
  getJobCategoriesEpic,
  addJobCategoriesEpic,
  deleteJobCategoryEpic,
  toggleJobStatusEpic,
  updateJobEpic,
  getImageUrlEpic,
  getSubCategories,
  toggleSubCategoriesStatus,
  deleteSubCategories,
  editSubCategoryEpic,
  AddJobSubCategoriesEpic,
  getInternDetailsEpic,
  toggleInternStatusEpic,
  deleteInternEpic,
  getRecruiterDetailsEpic,
  toggleRecruiterStatusEpic,
  deleteRecruiterEpic,
  getSupportDetailsEpic,
  changeSupportStatusAsSolvedEpic,
  getAdvertisementListEpic,
  addNewAdvertisementEpic,
  updateAdvertisementEpic,
  deleteAdvertisementEpic,
  toggleAdvertisementStatusEpic,
  getRecruiterPostFeeEpic,
  deleteRecruiterPostFeeEpic,
  addRecruiterPostFeeEpic,
  updateRecruiterPostFeeEpic,
  toggleRecruiterFeeStatusEpic,
  getRecruiterFeeByPackageDaysEpic,
  getPackageDaysEpic,
  getRecruiterPostEpic,
  deleteRecruiterPostEpic,
  getSubscriptionPlanEpic,
  deleteSubscriptionPlanEpic,
  toggleSubscriptionPlanStatusEpic,
  addNewSubscriptionPlanEpic,
  updateSubscriptionPlanEpic,
  getAdminProfileEpic,
  changeAdminPasswordEpic,
  updateAdminProfileEpic,
  getPaymentListEpic,
  approvePaymentEpic,
  rejectPaymentEpic,
  getSubscriptionPlanPaymentListEpic,
  approveSubscriptionPlanPaymentEpic,
  rejectSubscriptionPlanPaymentEpic,
  getDashboardDataEpic,
  addMassNotificationEpic,
} from "./myEpics";

export const rootReducer = combineReducers({
  LoginReducer,
  InternshipCategoriesReducer,
  PrivacyAndTermsReducer,
  InternManagementReducer,
  RecruiterManagementReducer,
  SupportReducer,
  AdvertisementReducer,
  RecruiterPostFeeReducer,
  SubscriptionPlanReducer,
  AdminProfileReducer,
  PaymentManagementReducer,
  DashboardReducer,
  MassNotificationReducer,
});
export const rootEpic = combineEpics(
  authenticateAdminEpic,
  privacyPolicyEpic,
  privacyPolicyUpdateEpic,
  termsAndConditionsEpic,
  termsAndConditionsUpdateEpic,

  getJobCategoriesEpic,
  addJobCategoriesEpic,
  deleteJobCategoryEpic,
  toggleJobStatusEpic,
  updateJobEpic,
  getImageUrlEpic,
  getSubCategories,
  toggleSubCategoriesStatus,
  deleteSubCategories,
  editSubCategoryEpic,
  AddJobSubCategoriesEpic,

  getInternDetailsEpic,
  toggleInternStatusEpic,
  deleteInternEpic,

  getRecruiterDetailsEpic,
  toggleRecruiterStatusEpic,
  deleteRecruiterEpic,

  getSupportDetailsEpic,
  changeSupportStatusAsSolvedEpic,

  getAdvertisementListEpic,
  addNewAdvertisementEpic,
  updateAdvertisementEpic,
  deleteAdvertisementEpic,
  toggleAdvertisementStatusEpic,

  getRecruiterPostFeeEpic,
  deleteRecruiterPostFeeEpic,
  addRecruiterPostFeeEpic,
  updateRecruiterPostFeeEpic,
  toggleRecruiterFeeStatusEpic,
  getPackageDaysEpic,
  getRecruiterFeeByPackageDaysEpic,

  getRecruiterPostEpic,
  deleteRecruiterPostEpic,

  getSubscriptionPlanEpic,
  deleteSubscriptionPlanEpic,
  toggleSubscriptionPlanStatusEpic,
  addNewSubscriptionPlanEpic,
  updateSubscriptionPlanEpic,

  getAdminProfileEpic,
  changeAdminPasswordEpic,
  updateAdminProfileEpic,

  getPaymentListEpic,
  approvePaymentEpic,
  rejectPaymentEpic,
  getSubscriptionPlanPaymentListEpic,
  approveSubscriptionPlanPaymentEpic,
  rejectSubscriptionPlanPaymentEpic,
  getDashboardDataEpic,

  addMassNotificationEpic
);
