import React, { useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { useDispatch } from "react-redux";
const { TextArea } = Input;
const { Option } = Select;

const AddRecruiterPostFee = (props) => {
  const { modalHideHandlar } = props;
  const [option, setOption] = useState("FREE");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const submitPostFeeHandler = (values) => {
    const payload = {
      ...values,
      packageDays: +values.packageDays,
      postFee: +values.postFee,
    };
    dispatch({
      type: "ADD_RECRUITER_POST_FEE_REQUEST",
      payload,
    });
    form.resetFields();
    modalHideHandlar();
  };
  return (
    <>
      <Form
        form={form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ["postFeeType"]: "FREE",
          ["postFee"]: "0",
        }}
        onFinish={submitPostFeeHandler}
      >
        <Form.Item
          label="Post Fee Type"
          name="postFeeType"
          rules={[{ required: true, message: "Missing post fee type!" }]}
          wrapperCol={{ span: 24 }}
        >
          <Select
            style={{
              width: "150px",
            }}
            onChange={(value) => setOption(value)}
          >
            <Option value="FREE">FREE</Option>
            <Option value="POPULAR">POPULAR</Option>
            <Option value="RECOMMENDED">RECOMMENDED</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Package Days"
          name="packageDays"
          rules={[{ required: true, message: "Missing Package days!" }]}
        >
          <Input type="number" name="packageDays" />
        </Form.Item>
        <Form.Item
          label="Post Fee"
          name="postFee"
          rules={[{ required: true, message: "Missing post fee!" }]}
        >
          {option === "FREE" ? (
            <Input
              type="number"
              name="postFee"
              value="0"
              placeholder="0"
              disabled
            />
          ) : (
            <Input type="number" name="postFee" />
          )}
        </Form.Item>
        <Form.Item
          label="Post Description"
          name="postDescription"
          rules={[{ required: true, message: "Missing post description!" }]}
        >
          <TextArea name="postDescription" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddRecruiterPostFee;
