import React, { useEffect, useState } from "react";
import { Space, Table, Tag, Tooltip, Drawer, Popconfirm } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { MdDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import RecruiterDetails from "../UserDetails/RecruiterDetails";
import "./RecruiterManagement.css";
import TableSkeleton from "../../UI/TableSkeleton/tableSkelaton";

const columns = [
  {
    title: "Company",
    dataIndex: "companyName",
  },
  {
    title: "Location",
    dataIndex: "location",
  },
  {
    title: "Recruiter Name",
    dataIndex: "fullName",
  },
  {
    title: "Recruiter Email",
    dataIndex: "email",
  },
  {
    title: "Phone",
    dataIndex: "phone",
  },
  {
    title: "Services",
    dataIndex: "services",
    width: 100,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "disabled",
  },
  {
    title: "Actions",
    dataIndex: "actions",
  },
];

const RecruiterManagement = () => {
  const recruiterState = useSelector(
    (state) => state.RecruiterManagementReducer
  );
  const dispatch = useDispatch();
  const [isUserDetailsDrawerVisible, setIsUserDetailsDrawerVisible] =
    useState(false);
  const [detailsObj, setDetailsObj] = useState({});
  useEffect(() => {
    dispatch({ type: "GET_RECRUITER_DETAILS_REQUEST" });
  }, []);

  const data = recruiterState.recruiterDetails.data?.map((dataObj) => {
    return {
      key: dataObj.recruiterId,
      companyName: dataObj.companyName,
      location: dataObj.location,
      // companyEmail: dataObj.companyEmail,
      fullName: dataObj.userDetails.fullName,
      email: dataObj.userDetails.email,
      phone: dataObj.userDetails.phone,
      services: dataObj.services.map((services) => {
        return (
          <Tag color="blue" key={services} style={{ margin: "2px 3px" }}>
            {services}
          </Tag>
        );
      }),
      disabled: dataObj.userDetails.disabled ? (
        <p
          style={{
            display: "inline",
            padding: "2px 3px",
            border: "1px solid red",
            textAlign: "center",
            color: "red",
            borderRadius: "3px",
          }}
        >
          Disabled
        </p>
      ) : (
        <p
          style={{
            display: "inline",
            padding: "2px 3px",
            border: "1px solid green",
            textAlign: "center",
            color: "green",
            borderRadius: "3px",
          }}
        >
          Active
        </p>
      ),
      actions: (
        <Space size="middle">
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this recruiter?"
              onConfirm={() => {
                deleteRecruiter(dataObj.recruiterId);
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Show Details">
            <a onClick={() => showDrawer(dataObj)}>
              <div className="bordered">
                <EyeOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Disable/Enable Status">
            <a onClick={() => toggleStatus(dataObj.recruiterId)}>
              <div className="bordered">
                <MdDisabledVisible />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });

  const deleteRecruiter = (id) => {
    dispatch({
      type: "DELETE_RECRUITER_REQUEST",
      payload: id,
    });
  };

  const showDrawer = (dataObj) => {
    setIsUserDetailsDrawerVisible(true);
    setDetailsObj(dataObj);
  };

  const toggleStatus = (id) => {
    dispatch({
      type: "TOGGLE_RECRUITER_STATUS_REQUEST",
      payload: id,
    });
  };
  const handleEditCancel = () => {
    setIsUserDetailsDrawerVisible(false);
  };

  console.log("from recruiter component", recruiterState);
  return (
    <>
      {recruiterState.isLoading ? (
        <TableSkeleton />
      ) : (
        <Table bordered columns={columns} dataSource={data} />
      )}
      <Drawer
        title={detailsObj.companyName}
        placement="right"
        visible={isUserDetailsDrawerVisible}
        onClose={handleEditCancel}
        width={1150}
        footer={null}
      >
        <RecruiterDetails companyDetailsObj={detailsObj} />
      </Drawer>
    </>
  );
};

export default RecruiterManagement;
