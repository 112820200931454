import React from "react";
import { Table, Tag, Space, Tooltip, Drawer, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useState } from "react";
import SeePostDetails from "./SeePostDetails";

const columns = [
  {
    title: "Post Name",
    dataIndex: "postName",
  },
  {
    title: "Duration",
    dataIndex: "duration",
  },
  {
    title: "Salary Type",
    dataIndex: "salaryType",
  },
  {
    title: "Salary",
    dataIndex: "salary",
  },
  {
    title: "Requirements",
    dataIndex: "requirements",
  },
  {
    title: "Responsibilities",
    dataIndex: "responsibilities",
  },
  {
    title: "Actions",
    dataIndex: "Actions",
  },
];

const RecruiterPost = (props) => {
  const RecruiterPostState = useSelector(
    (state) => state.RecruiterPostFeeReducer
  );
  const dispatch = useDispatch();
  const [isSeePostDetailsDrawerVisible, setIsSeePostDetailsDrawerVisible] =
    useState();
  const [record, setRecord] = useState();
  useEffect(() => {
    dispatch({
      type: "GET_RECRUITER_POST_REQUEST",
      id: props.dataRecord.feeId,
    });
  }, [props.isGetRecruiterPostDrawerVisible]);
  const data = RecruiterPostState.recruiterPost?.data?.map((dataObj) => {
    return {
      key: dataObj.postDescription.postId,
      postName: dataObj.postDescription.postName,
      duration: dataObj.postDescription.duration,
      salaryType: dataObj.postDescription.salaryType,
      salary: dataObj.postDescription.salary,
      requirements: dataObj.postDescription.requirements.map(
        (requirement, index) => {
          return (
            <Tag key={index} color="green">
              {requirement}
            </Tag>
          );
        }
      ),
      responsibilities: dataObj.postDescription.responsibilities.map(
        (responsibility, index) => {
          return (
            <Tag key={index} color="blue">
              {responsibility}
            </Tag>
          );
        }
      ),
      Actions: (
        <Space size="middle">
          <Tooltip title="Delete Job">
            <Popconfirm
              title="Are you sure to delete this recruiter post fee?"
              onConfirm={() => {
                dispatch({
                  type: "DELETE_RECRUITER_POST_REQUEST",
                  id: dataObj.postDescription.postId,
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="See Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsSeePostDetailsDrawerVisible(true);
                  setRecord(dataObj);
                }}
              >
                <EyeOutlined />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  console.log("from recruiter post", RecruiterPostState.recruiterPost);
  return (
    <>
      <Table bordered columns={columns} dataSource={data} />
      <Drawer
        title={`Post Details`}
        placement="right"
        onClose={() => {
          setIsSeePostDetailsDrawerVisible(false);
        }}
        visible={isSeePostDetailsDrawerVisible}
        width={1150}
      >
        <SeePostDetails
          isSeePostDetailsDrawerVisible={isSeePostDetailsDrawerVisible}
          dataRecord={record}
          onClose={() => {
            setIsSeePostDetailsDrawerVisible(false);
          }}
        />
      </Drawer>
    </>
  );
};

export default RecruiterPost;
