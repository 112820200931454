import { message } from "antd";
const INIT_STATE = {
  subscriptionPlan: [],
  isLoading: false,
};

const SubscriptionPlanReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "GET_SUBSCRIPTION_PLAN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_SUBSCRIPTION_PLAN_REQUEST_SUCCESS": {
      return {
        ...state,
        subscriptionPlan: action.payload,
        isLoading: false,
      };
    }
    case "GET_SUBSCRIPTION_PLAN_REQUEST_FAILURE": {
      {
        action.payload?.message
          ? message.error(action.payload.message)
          : message.error("Unable to get Subscription plan!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_SUBSCRIPTION_PLAN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "DELETE_SUBSCRIPTION_PLAN_REQUEST_SUCCESS": {
      message.success("Subscription plan status deleted successfully");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_SUBSCRIPTION_PLAN_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Unable to delete Subscription plan!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_SUBSCRIPTION_PLAN_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "TOGGLE_SUBSCRIPTION_PLAN_STATUS_REQUEST_SUCCESS": {
      message.success("Subscription plan status changed successfully");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_SUBSCRIPTION_PLAN_STATUS_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Unable to change Subscription plan status!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_NEW_SUBSCRIPTION_PLAN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_NEW_SUBSCRIPTION_PLAN_REQUEST_SUCCESS": {
      message.success("Subscription plan added successfully");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_NEW_SUBSCRIPTION_PLAN_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Unable to add Subscription plan!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_SUBSCRIPTION_PLAN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "UPDATE_SUBSCRIPTION_PLAN_REQUEST_SUCCESS": {
      message.success("Plan updated successfully");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_SUBSCRIPTION_PLAN_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Unable to update!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return INIT_STATE;
  }
};

export default SubscriptionPlanReducer;
