import { message } from "antd";

const INIT_STATE = {
  recruiterDetails: [],
  isLoading: false,
};

const RecruiterManagementReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "GET_RECRUITER_DETAILS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_RECRUITER_DETAILS_REQUEST_SUCCESS":
      return {
        ...state,
        recruiterDetails: action.payload,
        isLoading: false,
      };
    case "GET_RECRUITER_DETAILS_REQUEST_FAILURE": {
      message.error("Unable to load recruiter list");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_RECRUITER_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "TOGGLE_RECRUITER_STATUS_REQUEST_SUCCESS": {
      message.success("Status changed");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_RECRUITER_STATUS_REQUEST_FAILURE": {
      message.error("Unable to change status");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_RECRUITER_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "DELETE_RECRUITER_REQUEST_SUCCESS": {
      message.success("Recruiter deleted");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_RECRUITER_REQUEST_FAILURE": {
      message.error("Unable to delete recruiter");
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return INIT_STATE;
  }
};

export default RecruiterManagementReducer;
