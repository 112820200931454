import { Button, Form, Input, Spin } from "antd";
import React from "react";
import Model from "../UI/Model/Model";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const ChangePassword = () => {
  const changePasswordState = useSelector((state) => state.AdminProfileReducer);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const changePasswordHandler = (values) => {
    console.log(values);
    dispatch({
      type: "CHANGE_ADMIN_PASSWORD_REQUEST",
      payload: values,
    });
    form.resetFields();
  };
  return (
    <Model>
      <Spin spinning={changePasswordState.isLoading}>
        <div
          style={{
            margin: " 0 25%",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            padding: "1rem 2rem",
          }}
        >
          <p
            style={{
              backgroundColor: "blue",
              padding: "1rem",
              color: "white",
              fontWeight: "600",
            }}
          >
            Change Password
          </p>
          <Form
            onFinish={changePasswordHandler}
            labelCol={{ span: 7 }}
            form={form}
          >
            <Form.Item
              label="Current Password"
              name="currentPassword"
              rules={[
                { required: true, message: "please enter current passowrd" },
              ]}
            >
              <Input type="Password" placeholder="Current Password" />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[{ required: true, message: "please enter new passowrd" }]}
            >
              <Input type="password" placeholder="New Password" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[{ required: true, message: "please enter new passowrd" }]}
            >
              <Input type="password" placeholder="Confirm Password" />
            </Form.Item>
            <Form.Item>
              <Button block type="primary" htmlType="submit">
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Model>
  );
};

export default ChangePassword;
