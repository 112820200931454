import { message } from "antd";
const INIT_STATE = {
  supportData: [],
  isLoading: false,
};

const SupportReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "GET_SUPPORT_DETAILS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SUPPORT_DETAILS_REQUEST_SUCCESS":
      return {
        ...state,
        supportData: action.payload,
        isLoading: false,
      };
    case "GET_SUPPORT_DETAILS_REQUEST_FAILURE": {
      message.error("Unable to load Support list!");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_SUPPORT_STATUS_AS_SOLVED_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "CHANGE_SUPPORT_STATUS_AS_SOLVED_REQUEST_SUCCESS": {
      {
        action.payload2.message
          ? message.success(action.payload2.message)
          : message.success("Support status changed as Solved");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_SUPPORT_STATUS_AS_SOLVED_REQUEST_FAILURE": {
      message.error(
        action.payload2.message ? action.payload2.message : "error"
      );
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return INIT_STATE;
  }
};

export default SupportReducer;
