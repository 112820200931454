import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";

const UpdateModel = (props) => {
  const { record } = props;
  const subCategoryState = useSelector(
    (state) => state.InternshipCategoriesReducer
  );
  const dispatch = useDispatch();

  const [url, setUrl] = useState();
  const [form] = Form.useForm();

  const imageSelectedHandler = (e) => {
    let formData = new FormData();
    formData.append("jobCategoryImage", e.target.files[0]);
    dispatch({
      type: "GET_IMAGE_URL_REQUEST",
      payload: formData,
    });
    setUrl(subCategoryState.imageUrl.path);
    console.log("image url", url);
  };

  const editSubmitHandler = (values) => {
    console.log("bvalues");

    const payloadData = {
      categoryName: values.categoryName,
      jobCategoryId: props.record.jobCategoryId,
      imageUrl: subCategoryState.imageUrl.path,
    };
    console.log("payload data", payloadData);
    dispatch({
      type: "UPDATE_JOB_REQUEST",
      payload: payloadData,
    });
    dispatch({
      type: "CLEAR_IMG_URL",
    });
    form.resetFields();
    props.onEditDrawerVisible();
  };
  console.log("props data expose", props);
  return (
    <>
      <Form
        form={form}
        fields={[{ name: "categoryName", value: props.record.categoryName }]}
        labelCol={{ span: 8 }}
        // wrapperCol={{ offset: 8 }}
        style={{
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
          padding: "1.5rem",
        }}
        onFinish={editSubmitHandler}
      >
        <Card>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Form.Item label="Category Image" name="jobImage">
              <input
                type="file"
                name="jobImage"
                className="jobImage"
                onChange={imageSelectedHandler}
              />

              <Image
                src={
                  subCategoryState.imageUrl.image
                    ? subCategoryState.imageUrl.image
                    : props.record.categoryImageUrl
                }
                alt="job category image"
                width={200}
                style={{ margin: "1rem 0 0 0", objectFit: "contain" }}
              />
            </Form.Item>
            <Form.Item label="Job category name" name="categoryName">
              <Input
                name="categoryName"
                style={{ width: "600px" }}
                onChange={(e) => {}}
              />
            </Form.Item>
          </div>
        </Card>
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={subCategoryState.isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UpdateModel;
