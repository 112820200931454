import { message } from "antd";
const INIT_STATE = {
  isLoading: false,
  jobCategories: [],
  jobSubCategories: [],
  imageUrl: "",
};

const InternshipCategoriesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "GET_JOB_CATEGORIES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_JOB_CATEGORIES_REQUEST_SUCCESS": {
      return {
        ...state,
        jobCategories: action.payload,
        isLoading: false,
      };
    }
    case "GET_JOB_CATEGORIES_REQUEST_FAILURE": {
      message.error("Unable to load job categories list");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_JOB_CATEGORIES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_JOB_CATEGORIES_REQUEST_SUCCESS": {
      message.success("Job Added");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_JOB_CATEGORIES_REQUEST_FAILURE": {
      action.payload.response.data.message
        ? message.error(action.payload.response.data.message)
        : message.error("Unable to add job");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_JOB_CATEGORY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "DELETE_JOB_CATEGORY_REQUEST_SUCCESS": {
      message.success("Job deleted");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_JOB_CATEGORY_REQUEST_FAILURE": {
      message.error("Unable to delete job");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_JOB_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "TOGGLE_JOB_STATUS_REQUEST_SUCCESS": {
      message.success("Job status changed");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_JOB_STATUS_REQUEST_FAILURE": {
      message.error("Unable change status");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_JOB_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "UPDATE_JOB_REQUEST_SUCCESS": {
      message.success("Job updated");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_JOB_REQUEST_FAILURE": {
      {
        action.payload.response.data.message
          ? message.error(action.payload.response.data.message)
          : message.error("Unable to update job!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "GET_IMAGE_URL_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_IMAGE_URL_REQUEST_SUCCESS": {
      message.success("Image Uploaded");
      return {
        ...state,
        imageUrl: action.payload,
        isLoading: false,
      };
    }
    case "GET_IMAGE_URL_REQUEST_FAILURE": {
      message.error("Image upload failed");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "GET_SUBCATEGORIES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_SUBCATEGORIES_REQUEST_SUCCESS": {
      return {
        ...state,
        jobSubCategories: action.payload,
        isLoading: false,
      };
    }
    case "GET_SUBCATEGORIES_REQUEST_FAILURE": {
      message.error("Unable to load job subcategories list!");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_SUBCATEGORY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "TOGGLE_SUBCATEGORY_REQUEST_SUCCESS": {
      message.success("Status changed");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_SUBCATEGORY_REQUEST_FAILURE": {
      message.error("Unable change status");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_SUBCATEGORY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "DELETE_SUBCATEGORY_REQUEST_SUCCESS": {
      message.success("Job subcategory deleted");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_SUBCATEGORY_REQUEST_FAILURE": {
      message.error("Unable to delete");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "EDIT_JOB_SUBCATEGORY": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "EDIT_JOB_SUBCATEGORY_SUCCESS": {
      message.success("subcatgory updated");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "EDIT_JOB_SUBCATEGORY_FAILURE": {
      message.error("Unable to update subcategory");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_JOB_SUB_CATEGORY": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_JOB_SUB_CATEGORY_SUCCESS": {
      message.success("Subcategories succesfully added");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_JOB_SUB_CATEGORY_FAILURE": {
      {
        action.payload2
          ? message.error(action.payload2)
          : message.error("Unable to add subcategory");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "CLEAR_IMG_URL": {
      return {
        ...state,
        imageUrl: "",
      };
    }
    default:
      return INIT_STATE;
  }
};

export default InternshipCategoriesReducer;
