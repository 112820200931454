import React, { Fragment, useState } from "react";
import { Button, Form, Input, Spin } from "antd";
import {
  LockOutlined,
  MailOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import classes from "./LoginComponent.module.css";
import Card from "../Card/Card";
import { useDispatch, useSelector } from "react-redux";
import "./Login.css";
import logo from "./../../../src/assets/logo.png";
import { adminLoginRequest } from "../../Redux/actions";
import { Navigate } from "react-router";
import { type } from "@testing-library/user-event/dist/type";

const LoginComponent = () => {
  const loginState = useSelector((state) => state.LoginReducer);
  const dispatch = useDispatch();
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPassword, setEnteredpassword] = useState("");
  let email, password;
  const passswordChangeHandler = (e) => {
    password = e.target.value;
    setEnteredpassword(password);
  };

  const emailChangeHandler = (e) => {
    email = e.target.value;
    setEnteredEmail(email);
  };
  const submitHandler = (e) => {
    const data = {
      email: enteredEmail,
      password: enteredPassword,
    };
    console.log(data);
    if (enteredEmail.length > 0 && enteredPassword.length > 0) {
      dispatch({
        type: "ADMIN_AUTH_REQUEST",
        payload: { adminCredential: data },
      });
    }
  };
  console.log("loginState isSuccessOnLogin", loginState.isSuccessOnLogin);
  return (
    <Fragment>
      {loginState.isSuccessOnLogin && (
        <Navigate to="/dashboard" replace={true} />
      )}
      <Spin spinning={loginState.isLoading}>
        <div className={classes.loginContainer}>
          <Card className={classes.Card}>
            <div className={classes.loginHeader}>Admin Login</div>
            <div className={classes.contentDiv}>
              <img src={logo} alt="IMSlogo" width={350} />
            </div>
            <Form
              name="basic"
              labelCol={{
                span: 5,
              }}
              wrapperCol={{
                span: 32,
              }}
              initialValues={{
                remember: false,
              }}
              autoComplete="off"
              className={classes.loginForm}
            >
              <div className={classes.inputDiv}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter valid email.",
                    },
                  ]}
                  className={classes.inputItem}
                >
                  <Input
                    prefix={
                      <MailOutlined
                        className={classes.icons}
                        style={{ fontSize: "16px", color: "rgb(0, 154, 154)" }}
                      />
                    }
                    size="large"
                    placeholder="Email"
                    value={enteredEmail}
                    onChange={emailChangeHandler}
                  />
                </Form.Item>
              </div>

              <div className={classes.inputDiv}>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                  className={classes.inputItem}
                >
                  <Input.Password
                    prefix={
                      <LockOutlined
                        className={classes.icons}
                        style={{ fontSize: "16px", color: "rgb(0, 154, 154)" }}
                      />
                    }
                    value={enteredPassword}
                    size="large"
                    placeholder="Password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    onChange={passswordChangeHandler}
                  />
                </Form.Item>
              </div>

              <Form.Item
                wrapperCol={{ offset: 10, span: 4 }}
                className={classes.buttonItem}
              >
                <Button
                  size="large"
                  type="primary"
                  htmlType="Login"
                  className={classes.Button}
                  onClick={submitHandler}
                >
                  Log In
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </Spin>
    </Fragment>
  );
};

export default LoginComponent;
