import { message } from "antd";
const INIT_STATE = {
  internDetails: [],
  isLoading: true,
};

const InternManagementReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "GET_INTERN_DETAILS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_INTERN_DETAILS_REQUEST_SUCCESS":
      return {
        ...state,
        internDetails: action.payload,
        isLoading: false,
      };
    case "GET_INTERN_DETAILS_REQUEST_FAILURE": {
      message.error("Unable to load Intern list");
      return {
        ...state,
        internDetails: action.payload,
        isLoading: false,
      };
    }
    case "TOGGLE_INTERN_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "TOGGLE_INTERN_STATUS_REQUEST_SUCCESS": {
      message.success("Intern status changed");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_INTERN_STATUS_REQUEST_FAILURE": {
      message.error("Status change failed");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_INTERN_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "DELETE_INTERN_REQUEST_SUCCESS": {
      message.success("Intern deleted");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_INTERN_REQUEST_FAILURE": {
      message.error("Unable to delete intern");
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return INIT_STATE;
  }
};

export default InternManagementReducer;
