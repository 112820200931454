import React from "react";
import classes from "./InternCategoryDetails.module.css";
import Card from "./../../Card/Card";
import moment from "moment";

const InternCategoryDetails = (props) => {
  console.log("disable status", props.dataRecord.disableStatus);
  return (
    <div className={classes.jobDetailsContainer}>
      <Card>
        <div className={classes.details}>
          <h3>Job Details</h3>
        </div>
        <div className={classes.header}>
          <div className={classes.title}>Job</div>
          <div className={classes.fromTitle}>
            {props.dataRecord.categoryName}
          </div>
        </div>

        <div className={classes.header}>
          <div className={classes.title}>Status</div>
          <div className={classes.fromTitle}>
            <p
              className={
                props.dataRecord.disableStatus === false
                  ? classes.statusStyleGreen
                  : classes.statusStyleRed
              }
            >
              {props.dataRecord.disableStatus === false ? "Active" : "Disabled"}
            </p>
          </div>
        </div>

        <div className={classes.header}>
          <div className={classes.title}>Added Date</div>
          <div className={classes.fromTitle}>
            {moment(props.dataRecord.addedDate).format("YYYY - MM - DD")}
          </div>
        </div>

        <div className={classes.header}>
          <div className={classes.title}>Added By</div>
          <div className={classes.fromTitle}>{props.dataRecord.addedBy}</div>
        </div>

        <div className={classes.header}>
          <div className={classes.title}>Updated Date</div>
          <div className={classes.fromTitle}>
            {props.dataRecord.updatedDate
              ? moment(props.dataRecord.updatedDate).format("YYYY - MM - DD")
              : ""}
          </div>
        </div>

        <div className={classes.header}>
          <div className={classes.title}>Updated By</div>
          <div className={`${classes.fromTitle}`}>
            {props.dataRecord.updatedBy}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default InternCategoryDetails;
