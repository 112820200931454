import React from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Form, Select, Input, Space, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
const { Option } = Select;
const { TextArea } = Input;

const EditSubscriptionPlan = (props) => {
  const dispatch = useDispatch();
  const [option, setOption] = useState("BASIC");
  const [subscriptionPlanDescription, setSubscriptionPlanDescription] =
    useState([""]);
  const [form] = Form.useForm();

  const submitSubscriptionPlanHandler = (values) => {
    const payload = {
      durationType: values.durationType,
      price: values.price,
      planDescription: subscriptionPlanDescription,
    };
    dispatch({
      type: "UPDATE_SUBSCRIPTION_PLAN_REQUEST",
      id: props.record.planId,
      payload,
    });
    form.resetFields();
    props.modalHideHandlar();
    console.log(values);
  };

  const handlePlanDescription = (e, index) => {
    const { name, value } = e.target;
    console.log(name);
    const list = [...subscriptionPlanDescription];
    list[index] = value;
    setSubscriptionPlanDescription(list);
  };

  const removeJobInput = (index) => {
    const list = [...subscriptionPlanDescription];
    list.splice(index, 1);
    setSubscriptionPlanDescription(list);
  };
  const addJobInput = () => {
    setSubscriptionPlanDescription([...subscriptionPlanDescription, ""]);
  };
  console.log("descriptions", subscriptionPlanDescription);
  return (
    <>
      <Form
        layout="vertical"
        form={form}
        fields={[
          {
            name: ["durationType"],
            value: props.record.durationType,
          },
          {
            name: ["price"],
            value: props.record.price,
          },
          {
            name: ["planDesc"],
            value: props.record.planDesc,
          },
        ]}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ["planType"]: "BASIC",
        }}
        onFinish={submitSubscriptionPlanHandler}
      >
        <Form.Item
          label="Duration Type"
          name="durationType"
          rules={[{ required: true, message: "Missing plan type!" }]}
        >
          <Select>
            <Option value="WEEKLY">WEEKLY</Option>
            <Option value="MONTHLY">MONTHLY</Option>
            <Option value="SEMI_ANNUALLY">SEMI_ANNUALLY</Option>
            <Option value="ANNUALLY">ANNUALLY</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: "Missing price!" }]}
        >
          <Input type="number" name="price" />
        </Form.Item>
        {subscriptionPlanDescription.map((singleDescription, index) => {
          return (
            <div key={index}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Form.Item
                  label="Plan Description"
                  labelCol={{
                    span: 7,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  rules={[
                    { required: true, message: "Missing plan description!" },
                  ]}
                  style={{ width: "100%" }}
                >
                  <TextArea
                    name="planDesc"
                    onChange={(e) => handlePlanDescription(e, index)}
                  />
                </Form.Item>
                {subscriptionPlanDescription.length > 1 && (
                  <Button
                    onClick={() => removeJobInput(index)}
                    style={{
                      backgroundColor: "rgb(255, 80, 80, 0.8)",
                      color: "white",
                      gap: "1rem",
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                )}
              </div>
              {subscriptionPlanDescription.length - 1 === index && (
                <Button block type="dashed" onClick={addJobInput}>
                  <PlusOutlined />
                  Add plan description
                </Button>
              )}
            </div>
          );
        })}
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditSubscriptionPlan;
