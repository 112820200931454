import React, { useEffect, useState } from "react";
import Model from "../UI/Model/Model";
import AddSubscriptionPlan from "./AddSubscriptionPlan";
import EditSubscriptionPlan from "./EditSubscriptionPlan";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Table, Input, Space, Tooltip, Popconfirm } from "antd";
import {
  PlusOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { MdDisabledVisible } from "react-icons/md";
import moment from "moment";
import TableSkeleton from "../UI/TableSkeleton/tableSkelaton";
const { Search } = Input;

const columns = [
  {
    title: "Duration Type",
    dataIndex: "durationType",
  },

  {
    title: "Plan Duration",
    dataIndex: "planDuration",
  },
  {
    title: "Price",
    dataIndex: "price",
  },
  {
    title: "Added Date",
    dataIndex: "addedDate",
  },
  {
    title: "Updated Date",
    dataIndex: "updatedDate",
  },
  {
    title: "Status",
    dataIndex: "disabled",
  },
  {
    title: "Actions",
    dataIndex: "Actions",
  },
];

const SubscriptionPlan = () => {
  const subscriptionPlanState = useSelector(
    (state) => state.SubscriptionPlanReducer
  );
  const dispatch = useDispatch();
  const [isAddSubscriptionPlanVisible, setIsAddSubscriptionPlanVisible] =
    useState();
  const [isEditSubscriptionPlanVisible, setIsEditSubscriptionPlanVisible] =
    useState();
  const [record, setRecord] = useState();
  useEffect(() => {
    dispatch({
      type: "GET_SUBSCRIPTION_PLAN_REQUEST",
    });
  }, []);

  const data = subscriptionPlanState.subscriptionPlan?.map((dataObj) => {
    return {
      key: dataObj.planId,
      durationType: dataObj.durationType,
      planDuration: dataObj.planDuration,
      price: dataObj.price,
      addedDate: moment(dataObj.addedDate).format("YYYY-MM-DD"),
      updatedDate: dataObj.updatedDate
        ? moment(dataObj.updatedDate).format("YYYY-MM-DD")
        : "",
      disabled: dataObj.disabled ? (
        <p
          style={{
            display: "inline",
            padding: "2px 3px",
            border: "1px solid red",
            textAlign: "center",
            color: "red",
            borderRadius: "3px",
          }}
        >
          Disabled
        </p>
      ) : (
        <p
          style={{
            display: "inline",
            padding: "2px 3px",
            border: "1px solid green",
            textAlign: "center",
            color: "green",
            borderRadius: "3px",
          }}
        >
          Active
        </p>
      ),
      Actions: (
        <Space size="middle">
          <Tooltip title="Edit Advertisement">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsEditSubscriptionPlanVisible(true);
                  setRecord(dataObj);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Delete subscription plan">
            <Popconfirm
              title="Are you sure to delete this subscription plan?"
              onConfirm={() => {
                dispatch({
                  type: "DELETE_SUBSCRIPTION_PLAN_REQUEST",
                  id: dataObj.planId,
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined />
                </div>
              </a>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Disable/Enable Job">
            <a
              onClick={() => {
                dispatch({
                  type: "TOGGLE_SUBSCRIPTION_PLAN_STATUS_REQUEST",
                  id: dataObj.planId,
                });
              }}
            >
              <div className="bordered">
                <MdDisabledVisible />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  console.log("from subscription plan", subscriptionPlanState.isLoading);
  return (
    <Model>
      <div>
        <div className="advertiseHeader">
          <div className="headerAndButton">
            <h3>Subscription Plan</h3>
            <Button
              type="primary"
              onClick={() => {
                setIsAddSubscriptionPlanVisible(true);
              }}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
            <Modal
              title="Add New Subscription Plan"
              visible={isAddSubscriptionPlanVisible}
              onCancel={() => {
                setIsAddSubscriptionPlanVisible(false);
              }}
              footer={null}
            >
              <AddSubscriptionPlan
                modalHideHandlar={() => setIsAddSubscriptionPlanVisible(false)}
              />
            </Modal>
          </div>
        </div>

        <div>
          {subscriptionPlanState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={data}
              style={{ marginTop: "1.5rem" }}
            />
          )}
        </div>
        <Modal
          title="Edit Subscription Plan"
          visible={isEditSubscriptionPlanVisible}
          onCancel={() => {
            setIsEditSubscriptionPlanVisible(false);
          }}
          footer={null}
        >
          <EditSubscriptionPlan
            record={record}
            modalHideHandlar={() => setIsEditSubscriptionPlanVisible(false)}
          />
        </Modal>
      </div>
    </Model>
  );
};

export default SubscriptionPlan;
