import React, { useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DashboardOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { FiLock } from "react-icons/fi";
import { AiOutlineFileProtect, AiOutlineNotification } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { RiAdvertisementLine } from "react-icons/ri";
import {
  MdOutlineAttachMoney,
  MdOutlinePayment,
  MdPriceCheck,
} from "react-icons/md";
import { Layout, Menu } from "antd";
import "./Model.css";
import Profile from "../../Profile/Profile";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
const { Header, Sider, Content } = Layout;

const Model = ({ children }) => {
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/dashboard"
      : location.pathname
  );
  const siteFlex = {
    display: "flex",
  };
  const clickHandler = (e) => {
    console.log(e.key, "this is the key");
    setCurrent(e.key);
  };
  return (
    <div className="container">
      <Layout hasSider={true} style={{ display: "flex" }}>
        <Sider
          className="siderOnMobile"
          trigger={null}
          breakpoint="lg"
          collapsible
          collapsed={collapsed}
          width={230}
          style={{
            background: "white",
            height: "100vh",
            position: "sticky",
            backgroundColor: "#1D1C40",
            overflowY: "hidden",
            top: 0,
            left: 0,
            minWidth: "256px! important",
            maxWidth: "256px! important",
            width: "256px! important",
          }}
        >
          <div className="logo">
            <h1>IMS ADMIN</h1>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            onClick={clickHandler}
            selectable={true}
            selectedKeys={current}
            items={[
              {
                key: "1",
                icon: <DashboardOutlined />,
                label: <Link to="/dashboard">Dashboard</Link>,
              },
              {
                link: "/user-management",
                key: "2",
                icon: <UserOutlined />,
                label: <Link to="/user-management">User Management</Link>,
              },
              {
                link: "/internship-categories",
                key: "3",
                icon: <UsergroupAddOutlined />,
                label: (
                  <Link to="/internship-categories">Internship Categories</Link>
                ),
              },
              {
                link: "/privacy-policy",
                key: "4",
                icon: <FiLock />,
                label: <Link to="/privacy-policy">Privacy Policy</Link>,
              },
              {
                key: "5",
                icon: <AiOutlineFileProtect />,
                label: (
                  <Link to="/terms-and-conditions">Terms and Conditions</Link>
                ),
              },
              {
                key: "6",
                icon: <BiSupport />,
                label: <Link to="/support">Support Management</Link>,
              },
              {
                key: "7",
                icon: <RiAdvertisementLine />,
                label: <Link to="/advertisement">Advertisement</Link>,
              },
              {
                key: "8",
                icon: <MdOutlineAttachMoney />,
                label: <Link to="/recruiter-post-fee">Recruiter Post Fee</Link>,
              },
              {
                key: "9",
                icon: <MdPriceCheck />,
                label: <Link to="/subscription-plan">Subscription Plan</Link>,
              },
              {
                key: "10",
                icon: <MdOutlinePayment />,
                label: <Link to="/payment-management">Payment Management</Link>,
              },
              {
                key: "11",
                icon: <AiOutlineNotification />,
                label: (
                  <Link to="/mass-notification">Mass Notification System</Link>
                ),
              },
            ]}
          />
        </Sider>
        <Layout className="site-layout" style={siteFlex}>
          <Header className="site-layout-background">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: () => setCollapsed(!collapsed),
              }
            )}
            <Profile />
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default Model;
