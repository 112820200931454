import React from "react";
import "./Profile.css";
import profileImg from "./../../assets/download.png";
import { Dropdown, Menu } from "antd";
import { DownOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import { AiOutlineLogout } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

const Profile = () => {
  const dispatch = useDispatch();
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          icon: <UserOutlined />,
          label: <Link to="/profile">Profile</Link>,
        },
        {
          key: "2",
          icon: <LockOutlined />,
          label: <Link to="/change-password">Change Password</Link>,
        },
        {
          key: "3",
          icon: <AiOutlineLogout />,
          label: <a>Log Out</a>,
          onClick: () => {
            dispatch({ type: "ADMIN_LOGOUT" });
          },
        },
      ]}
    />
  );
  return (
    <>
      <div>
        <Dropdown overlay={menu} style={{ display: "flex" }}>
          <div className="dropdown">
            <div className="imageHeader">
              <img className="imgClass" src={profileImg} alt="profie" />
            </div>
            <h3>
              Super Admin <DownOutlined />
            </h3>
          </div>
        </Dropdown>
      </div>
    </>
  );
};

export default Profile;
