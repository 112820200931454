import React, { useState } from "react";
import Card from "./../../Card/Card";
import { Form, Input, Button, Space, Upload, Select, Drawer } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
const { Option } = Select;
const { TextArea } = Input;

const EditAdvertisement = (props) => {
  const [record, setRecord] = useState({
    advertisementName: props.advertisementName ? props.advertisementName : "",
    companyName: props.companyName ? props.companyName : "",
    companyURL: props.companyURL ? props.companyURL : "",
    advertisementURL: props.advertisementURL ? props.advertisementURL : "",
    companyDetails: props.companyDetails ? props.companyDetails : "",
  });
  const [form] = Form.useForm();
  const advertisementState = useSelector((state) => state.AdvertisementReducer);
  const dispatch = useDispatch();
  const [editContent, setEditContent] = useState(null);
  const [value, setValue] = useState(false);
  let files = {};
  const editAdvertisementContentOnChange = (e) => {
    files = e.target.files[0];
    // setValue(e.target.files[0]);
    // e.preventDefault();
    // console.warn(e.target.files[0]);
    // setValue(true);
    // setEditContent(e.target.files[0]);
    console.log(files, "files");
  };
  const submitEditAdvertisement = (values) => {
    const formData = new FormData();
    formData.append("advertisementId", record.id);
    formData.append("advertisementName", values.advertisementName);
    formData.append("advertisementDetails", values.advertisementDetails);
    formData.append("company Details", values.companyDetails);
    formData.append("companyName", values.companyName);
    formData.append("companyUrl", values.companyUrl);
    formData.append("userType", values.userType);
    Object.keys(files).length !== 0 && formData.append("content", files);

    console.log("From edit advertisement values", values);
    dispatch({
      type: "UPDATE_ADVERTISEMENT_REQUEST",
      payload: formData,
    });
    form.resetFields();
    props.onHideEditAdvertisementDrawer();
  };
  console.log("record from edit advertisement", record);
  return (
    <Drawer
      title="Edit Advertisement"
      placement="right"
      visible={typeof props.isEditAdvertisementDrawerVisible == "object"}
      onClose={() => {
        props.setIsEditAdvertisementDrawerVisible(false);
      }}
      afterVisibleChange={(a) => {
        // props.onOpen(a);
        if (a) {
          console.log("hasdfads", props.isEditAdvertisementDrawerVisible);
          setRecord(props.isEditAdvertisementDrawerVisible);
        }
      }}
      width={1150}
      footer={null}
    >
      <div style={{ margin: "0 3rem" }}>
        {/* <div> asdf ads fas {JSON.stringify(record)}</div> */}
        <Card>
          <Form
            encType="multipart/form-data"
            form={form}
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={submitEditAdvertisement}
            style={{ padding: "1rem 2rem" }}
            fields={[
              {
                name: ["advertisementName"],
                value: props.record.advertisementName,
              },
              {
                name: ["advertisementDetails"],
                value: props.record.advertisementDetails,
              },
              {
                name: ["companyName"],
                value: props.record.companyName,
              },
              {
                name: ["companyUrl"],
                value: props.record.companyURL,
              },
              {
                name: ["companyDetails"],
                value: props.record.companyDetails,
              },
              {
                name: ["userType"],
                value: props.record.userType,
              },
            ]}
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Form.Item
              label="Advertisement Name"
              name="advertisementName"
              rules={[
                { required: true, message: "Please enter advertisement name!" },
              ]}
            >
              <Input
                defaultValue={record.advertisementName}
                value={record.advertisementName}
                name="advertisementName"
                placeholder="Ad name"
              />
            </Form.Item>
            <Form.Item
              label="Advertisement Details"
              name="advertisementDetails"
              rules={[
                {
                  required: true,
                  message: "Please enter advertisement details!",
                },
              ]}
            >
              <TextArea name="advertisementDetails" placeholder="Ad details" />
            </Form.Item>
            <Form.Item
              label="Company Name"
              name="companyName"
              rules={[
                { required: true, message: "Please enter advertisement name!" },
              ]}
            >
              <Input name="companyName" placeholder="Company name" />
            </Form.Item>
            <Form.Item
              label="Company URL"
              name="companyUrl"
              rules={[
                { required: true, message: "Please enter company url!" },
                {
                  type: "url",
                  message: "Company URL must be a valid url.",
                },
              ]}
            >
              <Input name="companyUrl" />
            </Form.Item>
            <Form.Item
              label="Company Details"
              name="companyDetails"
              rules={[
                { required: true, message: "Please enter company details!" },
              ]}
            >
              <TextArea name="companyDetails" placeholder="Company details" />
            </Form.Item>
            <Form.Item
              label="User Type"
              name="userType"
              rules={[{ required: true, message: "Please select user type!" }]}
            >
              <Select
                style={{
                  width: 120,
                }}
              >
                <Option value="ALL">ALL</Option>
                <Option value="INTERN">INTERN</Option>
                <Option value="RECRUITER">RECRUITER</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Content" name="content">
              <input
                name="content"
                type="file"
                onChange={editAdvertisementContentOnChange}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 10,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                // onClick={() => console.warn("ell")}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Drawer>
  );
};

export default EditAdvertisement;
