import {createStore,applyMiddleware, compose} from 'redux'
import {createEpicMiddleware} from 'redux-observable'

import {rootReducer} from './rootReducer'
import {rootEpic} from './rootReducer'


const epicMiddleware = createEpicMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(epicMiddleware))
)
epicMiddleware.run(rootEpic)

export default store;
