import React from "react";
import "./AdvertisementDetails.css";
import moment from "moment";

const AdvertisementDetails = (props) => {
  const { record } = props;
  return (
    <div style={{ margin: "0 1rem" }}>
      <div className="advertDiv">
        Advertisement Name: <span>{record.advertisementName}</span>
      </div>
      <div className="advertDiv">
        Advertisement Details: <span>{record.advertisementDetails}</span>
      </div>
      <div className="advertDiv">
        Company Name: <span>{record.companyName}</span>
      </div>
      <div className="advertDiv">
        User Type: <span>{record.userType}</span>
      </div>
      <div className="advertDiv">
        Company URL:{" "}
        <span>
          <a href={record.companyURL} target="_blank">
            {record.companyURL}
          </a>
        </span>
      </div>
      <div className="advertDiv">
        Added Admin ID: <span>{record.addedAdminId}</span>
      </div>
      <div className="advertDiv">
        Added Admin: <span>{record.addedAdminName}</span>
      </div>
      <div className="advertDiv">
        Added Date:{" "}
        <span>
          {record.addedOn ? moment(record.addedOn).format("YYYY-MM-DD") : ""}
        </span>
      </div>
      <div className="advertDiv">
        Updated Admin ID: <span>{record.updatedAdminId}</span>
      </div>
      <div className="advertDiv">
        Updated Admin: <span>{record.updatedAdminName}</span>
      </div>
      <div className="advertDiv">
        Updated Date:{" "}
        <span>
          {record.updatedOn
            ? moment(record.updatedOn).format("YYYY-MM-DD")
            : ""}
        </span>
      </div>
      <div className="advertDiv">
        Status:{" "}
        <span>
          {record.disabled ? (
            <p
              style={{
                display: "inline",
                padding: "2px 3px",
                border: "1px solid red",
                textAlign: "center",
                color: "red",
                borderRadius: "3px",
              }}
            >
              Disabled
            </p>
          ) : (
            <p
              style={{
                display: "inline",
                padding: "2px 3px",
                border: "1px solid green",
                textAlign: "center",
                color: "green",
                borderRadius: "3px",
              }}
            >
              Active
            </p>
          )}
        </span>
      </div>
    </div>
  );
};

export default AdvertisementDetails;
