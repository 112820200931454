import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Button, message, Upload, Form, Input, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./CategoriesModel.css";

const CategoriesModel = (props) => {
  const categoriesModelState = useSelector(
    (state) => state.InternshipCategoriesReducer
  );
  const [imgIndex, setImgIndex] = useState(0);
  const [jobCategory, setJobCategory] = useState([
    { categoryName: "", categoryImageUrl: "" },
  ]);
  const [form] = Form.useForm();
  console.log(jobCategory);

  const [uploadArray, setUploadArray] = useState([]);
  const dispatch = useDispatch();
  const onFinish = (values) => {
    console.log("this is the array", values.jobs);
    dispatch({
      type: "ADD_JOB_CATEGORIES_REQUEST",
      payload: values.jobs,
    });
  };

  const imageSelectedHandler = (e, index) => {
    console.log(e);
    const { name } = e.target;
    let formData = new FormData();
    formData.append(
      "jobCategoryImage",
      e.target.files[0],
      categoriesModelState.imageUrl
    );
    dispatch({
      type: "GET_IMAGE_URL_REQUEST",
      payload: formData,
    });

    console.log("job category", jobCategory);
  };

  useEffect(() => {
    if (categoriesModelState.imageUrl) {
      const list = [...jobCategory];
      console.log("this is from here", categoriesModelState.imageUrl);
      list[imgIndex]["categoryImageUrl"] = categoriesModelState.imageUrl.path;
      setJobCategory(list);
    }
  }, [categoriesModelState.imageUrl]);

  const addJobInput = () => {
    setJobCategory([
      ...jobCategory,
      { categoryName: "", categoryImageUrl: "" },
    ]);
  };
  const removeJobInput = (index) => {
    const list = [...jobCategory];
    list.splice(index, 1);
    setJobCategory(list);
  };

  const handleJobInput = (e, index) => {
    console.log(e, index);
    const { name, value } = e.target;
    console.log(name);
    const list = [...jobCategory];
    list[index][name] = value;
    setJobCategory(list);
  };

  const submitHandler = (e) => {
    console.log(jobCategory);
    dispatch({
      type: "ADD_JOB_CATEGORIES_REQUEST",
      payload: jobCategory,
    });
    setJobCategory([{ categoryName: "", categoryImageUrl: "" }]);
    form.resetFields();
    props.modalVisibleHandler();
  };
  console.log("imageUrl", categoriesModelState.imageUrl);
  return (
    <>
      <div style={{ margin: "0 10%" }}>
        <Form onFinish={submitHandler} form={form}>
          {jobCategory.map((singleJobCategory, index) => {
            return (
              <div key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    boxShadow:
                      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                    margin: "1rem 0",
                    padding: "10px 12px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Form.Item label="Category Name">
                      <Input
                        name="categoryName"
                        type="text"
                        value={singleJobCategory.categoryName}
                        onChange={(e) => {
                          handleJobInput(e, index);
                        }}
                        style={{ width: "28rem", marginBottom: "1rem" }}
                        required
                      />
                    </Form.Item>
                    <Form.Item name="categoryImageUrl" label="Category Image">
                      {" "}
                      <Input
                        name="categoryImageUrl"
                        type="file"
                        onChange={(e) => {
                          imageSelectedHandler(e, index);
                          setImgIndex(index);
                        }}
                        required
                      />
                    </Form.Item>
                  </div>
                  {jobCategory.length > 1 && (
                    <Button color="red" onClick={() => removeJobInput(index)}>
                      <CloseOutlined />
                    </Button>
                  )}
                </div>
                {jobCategory.length - 1 === index && (
                  <Button block type="dashed" onClick={addJobInput}>
                    <PlusOutlined />
                    Add job Category
                  </Button>
                )}
              </div>
            );
          })}
          <Form.Item wrapperCol={{ offset: 10 }}>
            <Button
              disabled={categoriesModelState.isLoading}
              style={{
                backgroundColor: "#009A9A",
                margin: "2rem 0",
                color: "white",
              }}
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default CategoriesModel;

{
  /* <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
      >
        {
          <Form.List name="jobs">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      label="Job Category"
                      {...restField}
                      name={[name, "categoryName"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing job category",
                        },
                      ]}
                    >
                      <Input placeholder="Job" />
                    </Form.Item>
                    <Form.Item
                      label="Image"
                      {...restField}
                      name={[name, "categoryImageUrl"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing job image",
                        },
                      ]}
                    >
                      <input
                        type="file"
                        name="jobImage"
                        className="jobImage"
                        onChange={imageSelectedHandler}
                        value={categoriesModelState.imageUrl.path}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="default"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Job Category
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        }

        <Form.Item>
          <Button type="primary" block htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form> */
}
