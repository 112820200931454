import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyPolicy } from "../../Redux/actions";
import { Skeleton } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./privacyTerms.css";
import Model from "../UI/Model/Model";
import TermsModel from "../UI/termsModel/TermsModel";
import TableSkeleton from "../UI/TableSkeleton/tableSkelaton";

const PrivacyTerms = () => {
  const privacyTermsState = useSelector(
    (state) => state.PrivacyAndTermsReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("frm privacy terms");
    dispatch(getPrivacyPolicy());
  }, []);
  const [policyId, setPolicyId] = useState();
  const [value, setValue] = useState(
    privacyTermsState.privacyPolicyTerms.privacyPolicyList?.[0].privacyPolicy
  );

  useEffect(() => {
    if (
      privacyTermsState.privacyPolicyTerms.privacyPolicyList?.[0].privacyPolicy
    ) {
      setValue(
        privacyTermsState.privacyPolicyTerms.privacyPolicyList?.[0]
          .privacyPolicy
      );
      setPolicyId(
        privacyTermsState.privacyPolicyTerms.privacyPolicyList?.[0].id
      );
    }
  }, [
    privacyTermsState.privacyPolicyTerms.privacyPolicyList?.[0].privacyPolicy,
  ]);

  const privacyPolicyHandler = () => {
    const updateValue = {
      id: policyId,
      privacyPolicy: value,
    };
    dispatch({
      type: "PRIVACY_POLICY_UPDATE_REQUEST",
      payload: updateValue,
    });
  };

  return (
    <Fragment>
      <TermsModel
        headerName="Privacy Policy"
        buttonName="Update Privacy Policy"
        onClickHandler={privacyPolicyHandler}
      >
        <ReactQuill theme="snow" value={value} onChange={setValue} />
      </TermsModel>
    </Fragment>
  );
};

export default PrivacyTerms;

{
  /* <Model>
  <Card>
    <div className="termsHeader">Privacy policy</div>
    <ReactQuill theme="snow" value={value} onChange={setValue} />
    <div className="termsbutton">
      <Button type="primary">Update Privacy Policy</Button>
    </div>
  </Card>
</Model>; */
}

// {
//   /* <div className="contentContainer">
// <div className="header">Privacy Policy</div>
// <div>
//   {privcyTermsState.privactyPolicyTerms.privacyPolicyList?.map(
//     (privacyTerms) => {
//       const privacyPolicy = privacyTerms.privacyPolicy;
//       return <div key={privacyTerms.id}>{privacyPolicy}</div>;
//     }
//   )}
// </div>
// <button>Add Privacy Policy</button>
// <button>Update Privacy Policy</button>
// </div> */
// }

// {privcyTermsState.privactyPolicyTerms.privacyPolicyList?.map(
//   (privacyTerms) => {
//     const privacyPolicy = privacyTerms.privacyPolicy;
//     return (
//       <ReactQuill
//         key={privacyTerms.id}
//         theme="snow"
//         value={value}
//         onChange={setValue}
//       />
//     );
//   }
// )}
