import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  profile: {},
  password: {},
};

const AdminProfileReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "GET_ADMIN_PROFILE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_ADMIN_PROFILE_REQUEST_SUCCESS": {
      return {
        ...state,
        profile: action.payload,
        isLoading: false,
      };
    }
    case "GET_ADMIN_PROFILE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "CHANGE_ADMIN_PASSWORD_REQUEST_SUCCESS": {
      message.success("Password changed successfully");
      return {
        ...state,
        password: action.payload,
      };
    }
    case "CHANGE_ADMIN_PASSWORD_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Unable to change password!");
      }
      return {
        ...state,
      };
    }
    case "UPDATE_ADMIN_PROFILE_REQUEST_SUCCESS": {
      message.success("profile updated successfully");
      return {
        ...state,
      };
    }
    case "UPDATE_ADMIN_PROFILE_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Unable to update profile!");
      }
      return {
        ...state,
      };
    }
    default:
      return INIT_STATE;
  }
};

export default AdminProfileReducer;
