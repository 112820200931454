import React, { useEffect, useState } from "react";
import "./InternshipCategories.css";
import Model from "../UI/Model/Model";
import { Image, Popconfirm, Space, Table, Tag } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { MdDisabledVisible } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { Button, Input, Modal, Tooltip, Drawer } from "antd";
import CategoriesModel from "./CategoriesModel/CategoriesModel";
import UpdateModel from "./UpdateJobModel/UpdateModel";
import InternCategoryDetails from "./InternCategoryDetails/InternCategoryDetails";
import moment from "moment/moment";
import TableSkeleton from "../UI/TableSkeleton/tableSkelaton";
import SubCategories from "./SubCategories";
import { AiOutlineApartment } from "react-icons/ai";
const { Search } = Input;

const InternshipCategories = () => {
  const internCategoriesState = useSelector(
    (state) => state.InternshipCategoriesReducer
  );
  const dispatch = useDispatch();

  const [isAddJobDrawerVisible, setIsAddJobDrawerVisible] = useState(false);
  const [isSubCategoriesDrawerVisible, SetSubCategoriesDrawerVisible] =
    useState(false);

  const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false);
  const [updateRecord, setUpdateRecord] = useState(null);
  const [isDrawerVisible, setDrawerVisible] = useState();
  const [record, setRecord] = useState();
  const [searchedJob, setSearchedJob] = useState([]);
  const [isSearchFound, setIsSearchFound] = useState();

  useEffect(() => {
    dispatch({ type: "GET_JOB_CATEGORIES_REQUEST" });
  }, [isEditDrawerVisible]);

  const columns = [
    {
      title: "Job",
      dataIndex: "categoryName",
    },
    {
      title: "Image",
      dataIndex: "categoryImageUrl",
    },
    {
      title: "Added Date",
      dataIndex: "addedDate",
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
    },
    {
      title: "Updated Date",
      dataIndex: "updatedDate",
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
    },
    {
      title: "Status",
      dataIndex: "disableStatus",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  const deleteJob = (id) => {
    dispatch({
      type: "DELETE_JOB_CATEGORY_REQUEST",
      payload: id,
    });
  };
  const toggleStatus = (id) => {
    dispatch({
      type: "TOGGLE_JOB_STATUS_REQUEST",
      payload: id,
    });
  };

  const updateJob = () => {
    setIsEditDrawerVisible(true);
  };

  const data =
    internCategoriesState.jobCategories &&
    internCategoriesState.jobCategories?.map((dataObj) => {
      return {
        key: dataObj.jobCategoryId,
        action: (
          <Space size="middle">
            <Tooltip title="Edit Job">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsEditDrawerVisible(true);
                    setUpdateRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Delete Job">
              <Popconfirm
                title="Are you sure to delete this job category?"
                onConfirm={() => {
                  deleteJob(dataObj.jobCategoryId);
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Show Details">
              <a onClick={() => showDrawer(dataObj)}>
                <div className="bordered">
                  <EyeOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Disable/Enable Job">
              <a onClick={() => toggleStatus(dataObj.jobCategoryId)}>
                <div className="bordered">
                  <MdDisabledVisible />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Manage Sub-Categories">
              <a onClick={() => showSubCategoriesDrawer(dataObj)}>
                <div className="bordered">
                  <AiOutlineApartment />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
        categoryName: dataObj.categoryName,
        categoryImageUrl: (
          <Image
            src={dataObj.categoryImageUrl}
            style={{ height: "50px", width: "50px" }}
          />
        ),
        addedDate: dataObj.addedDate
          ? moment(dataObj.addedDate).format("YYYY-MM-DD")
          : "",
        addedBy: dataObj.addedBy,
        updatedDate: dataObj.updatedDate
          ? moment(dataObj.updatedDate).format("YYYY-MM-DD")
          : "",
        updatedBy: dataObj.updatedBy,
        disableStatus: dataObj.disableStatus ? (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid red",
              textAlign: "center",
              color: "red",
              borderRadius: "3px",
            }}
          >
            {" "}
            Disabled
          </p>
        ) : (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid green",
              textAlign: "center",
              color: "green",
              borderRadius: "3px",
            }}
          >
            Active
          </p>
        ),
      };
    });

  const showModal = () => {
    setIsAddJobDrawerVisible(true);
  };

  const handleOk = () => {
    setIsAddJobDrawerVisible(false);
  };

  const handleCancel = () => {
    setIsAddJobDrawerVisible(false);
  };

  const handleEditCancel = () => {
    setIsEditDrawerVisible(false);
  };

  const showDrawer = (record) => {
    setDrawerVisible(true);
    setRecord(record);
  };
  const showSubCategoriesDrawer = (record) => {
    SetSubCategoriesDrawerVisible(true);
    setRecord(record);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  // const searchOnchange = (e) => {
  //   const search = e.target.value;
  //   if (search.length > 0) {
  //     const filteredJob = internCategoriesState?.jobCategories.filter((record) => {
  //       return record.categoryName.toLowerCase().includes(search.toLowerCase());
  //     });
  //     if (filteredJob.length > 0) {
  //       const searchedData =
  //         filteredJob &&
  //         filteredJob?.map((dataObj) => {
  //           const newDate = new Date(dataObj.addedDate);
  //           const addedFullYear = newDate.getFullYear();
  //           const addedMonth = newDate.getMonth();
  //           const addedNewDate = newDate.getDate();

  //           const updateDate = new Date(dataObj.updatedDate);
  //           const updateFullYear = updateDate.getFullYear();
  //           const updateMonth = updateDate.getMonth();
  //           const updateNewDate = updateDate.getDate();

  //           return {
  //             key: dataObj.jobCategoryId,
  //             categoryName: dataObj.categoryName,
  //             categoryImageUrl: (
  //               <img
  //                 src={dataObj.categoryImageUrl}
  //                 style={{ height: "50px", width: "50px" }}
  //               />
  //             ),
  //             addedDate: `${addedFullYear}-${addedMonth + 1}-${addedNewDate}`,
  //             addedBy: dataObj.addedBy,
  //             updatedDate: dataObj.updatedDate
  //               ? `${updateFullYear}-${updateMonth + 1}-${updateNewDate}`
  //               : "",
  //             updatedBy: dataObj.updatedBy,
  //             disableStatus: !dataObj.disableStatus ? (
  //               <p
  //                 style={{
  //                   border: "1px solid red",
  //                   textAlign: "center",
  //                   color: "red",
  //                   borderRadius: "3px",
  //                 }}
  //               >
  //                 {" "}
  //                 Disabled
  //               </p>
  //             ) : (
  //               <p
  //                 style={{
  //                   border: "1px solid green",
  //                   textAlign: "center",
  //                   color: "green",
  //                   borderRadius: "3px",
  //                 }}
  //               >
  //                 Active
  //               </p>
  //             ),
  //           };
  //         });
  //       setSearchedJob(searchedData);
  //     }
  //     console.log(search);
  //     if (filteredJob.length > 0) {
  //       setIsSearchFound(true);
  //     } else {
  //       setIsSearchFound(false);
  //     }
  //     console.log("is search found", isSearchFound);
  //   } else {
  //     setSearchedJob(data);
  //   }
  // };
  return (
    <Model>
      <div>
        <div className="jobtypeAddHeader">
          <div className="jobHeader">
            <div className="ptypeDiv">
              <h3 className="ptype">Job Types</h3>
            </div>
            <Button
              type="primary"
              color="black"
              onClick={showModal}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
            <Drawer
              title="New Job"
              placement="right"
              visible={isAddJobDrawerVisible}
              onClose={handleCancel}
              width={1150}
              footer={null}
            >
              <CategoriesModel modalVisibleHandler={handleCancel} />
            </Drawer>
          </div>
          {/* <Search
            size="large"
            width={10}
            placeholder="Search Jobs"
            enterButton
          /> */}
        </div>
        <Drawer
          title="Edit Job Category"
          placement="right"
          visible={isEditDrawerVisible}
          onClose={handleEditCancel}
          width={1150}
          footer={null}
        >
          <UpdateModel
            record={updateRecord}
            isEditDrawerVisible={isEditDrawerVisible}
            onEditDrawerVisible={handleEditCancel}
          />
        </Drawer>
        <div className="tableWrapper">
          {internCategoriesState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered={true}
              columns={columns}
              dataSource={isSearchFound ? searchedJob : data}
            />
          )}
          <Drawer
            title={record?.categoryName}
            placement="right"
            onClose={onClose}
            visible={isDrawerVisible}
            width={1150}
          >
            <InternCategoryDetails dataRecord={record} />
          </Drawer>
          <Drawer
            title={record?.categoryName}
            placement="right"
            onClose={() => {
              SetSubCategoriesDrawerVisible(false);
            }}
            visible={isSubCategoriesDrawerVisible}
            width={1150}
          >
            <SubCategories
              record={record}
              isSubCategoriesDrawerVisible={isSubCategoriesDrawerVisible}
            />
          </Drawer>
        </div>
      </div>
    </Model>
  );
};

export default InternshipCategories;
