import { message } from "antd";

const INIT_STATE = {
  recruiterPostFeeList: [],
  recruiterPost: [],
  packageDays: [],
  isLoading: false,
};

const RecruiterPostFeeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "GET_RECRUITER_POST_FEE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_RECRUITER_POST_FEE_REQUEST_SUCCESS": {
      return {
        ...state,
        recruiterPostFeeList: action.payload,
        isLoading: false,
      };
    }
    case "GET_RECRUITER_POST_FEE_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_RECRUITER_POST_FEE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "DELETE_RECRUITER_POST_FEE_REQUEST_SUCCESS": {
      message.success("Post fee delete success!");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_RECRUITER_POST_FEE_REQUEST_FAILURE": {
      message.error("Unable to delete post fee!");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_RECRUITER_POST_FEE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_RECRUITER_POST_FEE_REQUEST_SUCCESS": {
      message.success("Post fee added successfully");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_RECRUITER_POST_FEE_REQUEST_FAILURE": {
      {
        action.payload?.message
          ? message.error(action.payload.message)
          : message.error("Unable to add post fee!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_POST_FEE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "UPDATE_POST_FEE_REQUEST_SUCCESS": {
      message.success("Post fee updated successfully");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_POST_FEE_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Unable to update post fee!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_RECRUITER_POST_FEE_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_RECRUITER_POST_FEE_STATUS_REQUEST_SUCCESS": {
      message.success("Fee status changed");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_RECRUITER_POST_FEE_STATUS_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Unable to change post fee status!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "GET_PACKAGE_DAYS_REQUEST_SUCCESS": {
      return {
        ...state,
        packageDays: action.payload,
      };
    }
    case "GET_PACKAGE_DAYS_REQUEST_FAILURE": {
      return {
        ...state,
      };
    }
    case "GET_RECRUITER_POST_FEE_BY_PACKAGE_DAYS_REQUEST_SUCCESS": {
      return {
        ...state,
        recruiterPostFeeList: action.payload,
      };
    }
    case "GET_RECRUITER_POST_FEE_BY_PACKAGE_DAYS_REQUEST_FAILURE": {
      return {
        ...state,
      };
    }
    case "GET_RECRUITER_POST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_RECRUITER_POST_REQUEST_SUCCESS": {
      return {
        ...state,
        recruiterPost: action.payload,
        isLoading: false,
      };
    }
    case "GET_RECRUITER_POST_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_RECRUITER_POST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "DELETE_RECRUITER_POST_REQUEST_SUCCESS": {
      message.success("Post deleted successfully!");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_RECRUITER_POST_REQUEST_FAILURE": {
      {
        action.payload.message
          ? message.error(action.payload.message)
          : message.error("Unable to delete post!");
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return INIT_STATE;
  }
};

export default RecruiterPostFeeReducer;
