import React, { useState } from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Form, Select, Input, Space, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;
const { TextArea } = Input;

const AddSubscriptionPlan = (props) => {
  const dispatch = useDispatch();
  const [subscriptionPlanDescription, setSubscriptionPlanDescription] =
    useState([""]);
  const [form] = Form.useForm();

  const submitSubscriptionPlanHandler = (values) => {
    const payload = {
      durationType: values.durationType,
      price: values.price,
      planDescription: subscriptionPlanDescription,
    };
    dispatch({ type: "ADD_NEW_SUBSCRIPTION_PLAN_REQUEST", payload });
    form.resetFields();
    setSubscriptionPlanDescription([""]);
    props.modalHideHandlar();
    console.log(values);
  };

  const handlePlanDescription = (e, index) => {
    const { name, value } = e.target;
    console.log(subscriptionPlanDescription);
    const list = [...subscriptionPlanDescription];
    list[index] = value;
    setSubscriptionPlanDescription(list);
  };

  const removeJobInput = (index) => {
    console.log("index", index);
    console.log("susREM", subscriptionPlanDescription);

    const list = [...subscriptionPlanDescription];
    list.splice(index, 1);
    setSubscriptionPlanDescription(list);
  };
  const addJobInput = () => {
    setSubscriptionPlanDescription([...subscriptionPlanDescription, ""]);
    console.log("susADD", subscriptionPlanDescription);
  };
  return (
    <>
      <Form
        layout="vertical"
        form={form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={submitSubscriptionPlanHandler}
      >
        <Form.Item
          label="Duration Type"
          name="durationType"
          rules={[{ required: true, message: "Missing plan type!" }]}
        >
          <Select>
            <Option value="WEEKLY">WEEKLY</Option>
            <Option value="MONTHLY">MONTHLY</Option>
            <Option value="SEMI_ANNUALLY">SEMI_ANNUALLY</Option>
            <Option value="ANNUALLY">ANNUALLY</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: "Missing price!" }]}
        >
          <Input type="number" name="price" />
        </Form.Item>
        {subscriptionPlanDescription.map((singleDescription, index) => {
          return (
            <div key={index}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Form.Item
                  label="Plan Description"
                  labelCol={{
                    span: 7,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  rules={[
                    { required: true, message: "Missing plan description!" },
                  ]}
                  style={{ width: "100%" }}
                >
                  <TextArea
                    value={singleDescription}
                    onChange={(e) => handlePlanDescription(e, index)}
                  />
                </Form.Item>
                {subscriptionPlanDescription.length > 1 && (
                  <Button
                    onClick={() => removeJobInput(index)}
                    style={{
                      backgroundColor: "rgb(255, 80, 80)",
                      color: "white",
                      gap: "1rem",
                      margin: "0.3rem",
                      borderRadius: "1rem",
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                )}
              </div>
              {subscriptionPlanDescription.length - 1 === index && (
                <Button block type="dashed" onClick={addJobInput}>
                  <PlusOutlined />
                  Add plan description
                </Button>
              )}
            </div>
          );
        })}

        {/* <Form.Item
          label="Post Description"
          name="postDescription"
          rules={[{ required: true, message: "Missing post description!" }]}
        >
          <TextArea name="postDescription" />
        </Form.Item> */}
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddSubscriptionPlan;
