import React from "react";
import Model from "../UI/Model/Model";
import { Table, Space, Tooltip, Modal, Button, Segmented, Image } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import moment from "moment/moment";
import { useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import TableSkeleton from "../UI/TableSkeleton/tableSkelaton";
const { confirm } = Modal;

const recruiterPostFeeColumns = [
  {
    title: "Company Name",
    dataIndex: "companyName",
  },
  {
    title: "Post Name",
    dataIndex: "postName",
  },
  {
    title: "Package Days",
    dataIndex: "packageDays",
  },
  {
    title: "Package Price",
    dataIndex: "packagePrice",
  },
  {
    title: "Payment Date",
    dataIndex: "paymentDate",
  },
  {
    title: "Expiry Date",
    dataIndex: "expiryDate",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Actions",
    dataIndex: "actions",
  },
];

const subscriptionPlanColumns = [
  {
    title: "Full Name",
    dataIndex: "fullName",
  },
  {
    title: "User Type",
    dataIndex: "userType",
  },
  {
    title: "Plan Type",
    dataIndex: "planType",
  },
  {
    title: "Plan Price",
    dataIndex: "planPrice",
  },
  {
    title: "Remaining Days",
    dataIndex: "remainingDays",
  },
  {
    title: "Profile Image",
    dataIndex: "userProfileImage",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Expired Status",
    dataIndex: "isExpired",
  },
  {
    title: "Actions",
    dataIndex: "actions",
  },
];

const PaymentManagement = () => {
  const paymentState = useSelector((state) => state.PaymentManagementReducer);
  const dispatch = useDispatch();
  const [paymentType, setPaymentType] = useState("Recruiter Post Fee");
  const [isPaymentAprovalModalVisible, setIsPaymentAprovalModalVisible] =
    useState();
  useEffect(() => {
    if (paymentType === "Recruiter Post Fee") {
      dispatch({
        type: "GET_PAYMENT_LIST_REQUEST",
      });
    } else if (paymentType === "Subscription Plan") {
      dispatch({
        type: "GET_SUBSCRIPTION_PLAN_PAYMENT_LIST_REQUEST",
      });
    }
  }, [paymentType]);

  const recruiterPostFeeData = paymentState.recruiterPostPaymentList?.map(
    (dataObj) => {
      let statusTag;
      if (dataObj.status === "FAILED") {
        statusTag = (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid red",
              textAlign: "center",
              color: "red",
              borderRadius: "3px",
            }}
          >
            FAILED
          </p>
        );
      } else if (dataObj.status === "PENDING") {
        statusTag = (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid blue",
              textAlign: "center",
              color: "blue",
              borderRadius: "3px",
            }}
          >
            PENDING
          </p>
        );
      } else if (dataObj.status === "APPROVED") {
        statusTag = (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid green",
              textAlign: "center",
              color: "green",
              borderRadius: "3px",
            }}
          >
            APPROVED
          </p>
        );
      } else if (dataObj.status === "REJECTED") {
        statusTag = (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid rgb(246, 82, 82)",
              textAlign: "center",
              color: "rgb(246, 82, 82)",
              borderRadius: "3px",
            }}
          >
            REJECTED
          </p>
        );
      } else {
        statusTag = "";
      }
      return {
        key: dataObj.paymentId,
        companyName: dataObj.companyName,
        postName: dataObj.postName,
        packageDays: dataObj.packageDays,
        packagePrice: dataObj.packagePrice,
        paymentDate: dataObj.paymentDate
          ? moment(dataObj.paymentDate).format("YYYY-MM-DD")
          : "",
        expiryDate: dataObj.expiryDate
          ? moment(dataObj.expiryDate).format("YYYY-MM-DD")
          : "",
        status: statusTag,
        actions:
          dataObj.status === "PENDING" ? (
            <Space size="middle">
              <Tooltip title="Approve Payment">
                <a
                  onClick={() => {
                    approvePaymentHandler(dataObj);
                  }}
                >
                  <div className="bordered">
                    <AiOutlineCheck />
                  </div>
                </a>
              </Tooltip>
              <Tooltip title="Reject Payment">
                <a
                  onClick={() => {
                    rejectPaymentHandler(dataObj);
                  }}
                >
                  <div className="bordered">
                    <AiOutlineClose />
                  </div>
                </a>
              </Tooltip>
            </Space>
          ) : (
            ""
          ),
      };
    }
  );
  const subscriptionPlanData = paymentState.subscriptionPlanPaymentList?.map(
    (dataObj) => {
      let statusTag;
      if (dataObj.status === "FAILED") {
        statusTag = (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid red",
              textAlign: "center",
              color: "red",
              borderRadius: "3px",
            }}
          >
            FAILED
          </p>
        );
      } else if (dataObj.status === "PENDING") {
        statusTag = (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid blue",
              textAlign: "center",
              color: "blue",
              borderRadius: "3px",
            }}
          >
            PENDING
          </p>
        );
      } else if (dataObj.status === "APPROVED") {
        statusTag = (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid green",
              textAlign: "center",
              color: "green",
              borderRadius: "3px",
            }}
          >
            APPROVED
          </p>
        );
      } else if (dataObj.status === "REJECTED") {
        statusTag = (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid rgb(246, 82, 82)",
              textAlign: "center",
              color: "rgb(246, 82, 82)",
              borderRadius: "3px",
            }}
          >
            REJECTED
          </p>
        );
      } else {
        statusTag = "";
      }
      return {
        key: dataObj.paymentId,
        fullName: dataObj.fullName,
        userType: dataObj.userType,
        planType: dataObj.planType,
        planPrice: dataObj.planPrice,
        remainingDays: dataObj.remainingDays,
        userProfileImage: dataObj.userProfileImage ? (
          <Image
            src={dataObj.userProfileImage}
            style={{ height: "50px", width: "50px" }}
          />
        ) : (
          ""
        ),
        isExpired: dataObj.isExpired ? (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              backgroundColor: "red",
              textAlign: "center",
              color: "white",
              borderRadius: "3px",
              fontWeight: "400",
            }}
          >
            Expired
          </p>
        ) : (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              backgroundColor: "green",
              textAlign: "center",
              color: "white",
              borderRadius: "3px",
              fontWeight: "400",
            }}
          >
            Active
          </p>
        ),
        status: statusTag,
        actions:
          dataObj.status === "PENDING" ? (
            <Space size="middle">
              <Tooltip title="Approve Payment">
                <a
                  onClick={() => {
                    approveSubscriptionPlanPaymentHandler(dataObj);
                  }}
                >
                  <div className="bordered">
                    <AiOutlineCheck />
                  </div>
                </a>
              </Tooltip>
              <Tooltip title="Reject Payment">
                <a
                  onClick={() => {
                    rejectSubscriptionPlanPaymentHandler(dataObj);
                  }}
                >
                  <div className="bordered">
                    <AiOutlineClose />
                  </div>
                </a>
              </Tooltip>
            </Space>
          ) : (
            ""
          ),
      };
    }
  );

  const approvePaymentHandler = (dataObj) => {
    confirm({
      title: "Do you Want to approve this payment?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Company Name: <span>{dataObj.companyName}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Post Name: <span>{dataObj.postName}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Package Days: <span>{dataObj.packageDays}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Package Price: <span>{dataObj.packagePrice}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Payment Date:{" "}
            <span>
              {dataObj.paymentDate
                ? moment(dataObj.paymentDate).format("YYYY-DD-MM")
                : ""}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Expiry Date:{" "}
            <span>
              {dataObj.expiryDate
                ? moment(dataObj.expiryDate).format("YYYY-MM-DD")
                : ""}
            </span>
          </div>
        </div>
      ),
      okText: "Approve",

      onOk() {
        dispatch({
          type: "APPROVE_PAYMENT_REQUEST",
          id: dataObj.paymentId,
        });
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const rejectPaymentHandler = (dataObj) => {
    confirm({
      title: "Do you Want to reject this payment?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Company Name: <span>{dataObj.companyName}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Post Name: <span>{dataObj.postName}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Package Days: <span>{dataObj.packageDays}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Package Price: <span>{dataObj.packagePrice}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Payment Date:{" "}
            <span>
              {dataObj.paymentDate
                ? moment(dataObj.paymentDate).format("YYYY-DD-MM")
                : ""}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Expiry Date:{" "}
            <span>
              {dataObj.expiryDate
                ? moment(dataObj.expiryDate).format("YYYY-MM-DD")
                : ""}
            </span>
          </div>
        </div>
      ),
      okText: "Reject",

      onOk() {
        dispatch({
          type: "REJECT_PAYMENT_REQUEST",
          id: dataObj.paymentId,
        });
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const approveSubscriptionPlanPaymentHandler = (dataObj) => {
    confirm({
      title: "Do you Want to approve this payment?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Full Name: <span>{dataObj.fullName}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            User Type: <span>{dataObj.userType}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Plan Type: <span>{dataObj.planType}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Plan Price: <span>{dataObj.planPrice}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Remaining Days: <span>{dataObj.remainingDays}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Added Date:{" "}
            <span>
              {dataObj.addedDate
                ? moment(dataObj.addedDate).format("YYYY-DD-MM")
                : ""}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Expiry Date:{" "}
            <span>
              {dataObj.expiryDate
                ? moment(dataObj.expiryDate).format("YYYY-MM-DD")
                : ""}
            </span>
          </div>
        </div>
      ),
      okText: "Approve",

      onOk() {
        dispatch({
          type: "APPROVE_SUBSCRIPTION_PLAN_PAYMENT_REQUEST",
          id: dataObj.paymentId,
        });
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const rejectSubscriptionPlanPaymentHandler = (dataObj) => {
    confirm({
      title: "Do you Want to reject this payment?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Full Name: <span>{dataObj.fullName}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            User Type: <span>{dataObj.userType}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Plan Type: <span>{dataObj.planType}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Plan Price: <span>{dataObj.planPrice}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Remaining Days: <span>{dataObj.remainingDays}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Added Date:{" "}
            <span>
              {dataObj.addedDate
                ? moment(dataObj.addedDate).format("YYYY-DD-MM")
                : ""}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0",
            }}
          >
            Expiry Date:{" "}
            <span>
              {dataObj.expiryDate
                ? moment(dataObj.expiryDate).format("YYYY-MM-DD")
                : ""}
            </span>
          </div>
        </div>
      ),
      okText: "Reject",

      onOk() {
        dispatch({
          type: "REJECT_SUBSCRIPTION_PLAN_PAYMENT_REQUEST",
          id: dataObj.paymentId,
        });
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onPaymentSegmentChange = (key) => {
    setPaymentType(key);
    console.log("anish key", key);
  };

  console.log("From payment management", paymentState.recruiterPostPaymentList);
  return (
    <Model>
      <div>
        <div className="advertiseHeader">
          <div className="headerAndButton">
            <h3>Payment Management</h3>
            <Segmented
              size="medium"
              options={["Recruiter Post Fee", "Subscription Plan"]}
              onChange={onPaymentSegmentChange}
              style={{ height: "34px", fontSize: "14px" }}
            />
            {/* <Button
              type="primary"
              onClick={() => {
                setIsAddSubscriptionPlanVisible(true);
              }}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
            <Modal
              title="Add New Subscription Plan"
              visible={isAddSubscriptionPlanVisible}
              onCancel={() => {
                setIsAddSubscriptionPlanVisible(false);
              }}
              footer={null}
            >
              <AddSubscriptionPlan
                modalHideHandlar={() => setIsAddSubscriptionPlanVisible(false)}
              />
            </Modal> */}
          </div>
        </div>

        <div>
          {paymentState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              expandable
              columns={
                paymentType === "Recruiter Post Fee"
                  ? recruiterPostFeeColumns
                  : subscriptionPlanColumns
              }
              dataSource={
                paymentType === "Recruiter Post Fee"
                  ? recruiterPostFeeData
                  : subscriptionPlanData
              }
              style={{ marginTop: "1.5rem" }}
            />
          )}
        </div>
        {/* <Modal
          title="Approve Payment?"
          visible={isPaymentAprovalModalVisible}
          okText="Confirm"
          onCancel={() => {
            setIsPaymentAprovalModalVisible(false);
          }}
        ></Modal> */}
      </div>
    </Model>
  );
};

export default PaymentManagement;
