import axios from "axios";
import { getCookie } from "./FrontendHelper";

// const token = getCookie("accessToken");

const HeaderData = async (update = false) => {
  const token = getCookie("accessToken");

  if (token === null) {
    return {
      "Content-Type": "application/json",
    };
  } else if (update) {
    return {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    };
  } else {
    //console.warn("this should work");
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }
};

// axios.defaults.baseURL = "http://192.168.10.67:8888/ims/v1/api";
axios.defaults.baseURL = "http://13.127.139.254:8080/ims/v1/api";

// export const API = axios.create({
//   baseURL: `http://13.127.139.254:8080/ims/v1/api`,
// });

export default class Api {
  async loginApi(payload) {
    try {
      const res = await axios.post("/admin/login", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async getAdminProfile(payload) {
    try {
      const res = await axios.get("/admin/profile", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeAdminPassword(payload) {
    try {
      const res = await axios.put("/admin/change-password", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateAdminProfile(payload) {
    try {
      const res = await axios.put("/admin/update-profile", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async privacyPolicy(payload) {
    try {
      const res = await axios.get("/privacy-terms/get-privacy-policy", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async privacyPolicyUpdate(payload) {
    try {
      const res = await axios.post(
        "/privacy-terms/admin/update/policy",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  //from here continue

  async termsAndConditions() {
    try {
      const res = await axios.get("/privacy-terms/get-terms-condition", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async termsAndConditionsUpdate(payload) {
    try {
      const res = await axios.post(
        "/privacy-terms/admin/update/terms-condition",
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async getJobCategories() {
    try {
      const res = await axios.get("/admin/job-category/list", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addJobCategories(payload) {
    try {
      const res = await axios.post("/admin/job-category", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteJobCategory(payload) {
    try {
      const res = await axios.delete(
        `/admin/job-category?jobCategoryId=${payload}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async toggleJobStatus(payload) {
    try {
      const res = await axios.patch(
        `/admin/job-category?jobCategoryId=${payload}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateJob(payload) {
    try {
      const res = await axios.put("/admin/job-category", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async getImageUrl(payload) {
    try {
      const res = await axios.post("admin/job-category/upload-image", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async getSubCategories(payload) {
    try {
      const res = await axios.get(
        `/admin/job-sub-category/list?jobCategoryId=${payload}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async toggleSubCategories(payload) {
    try {
      console.log("asdf", payload);

      const res = await axios.patch(
        `/admin/job-sub-category?jobSubCategoryId=${payload.subCatId}`,
        null,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteSubCategories(payload) {
    try {
      const res = await axios.delete(
        `/admin/job-sub-category?jobSubCategoryId=${payload.subCatId}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async editSubCategory(id, payload) {
    try {
      const res = await axios.put(
        `/admin/job-sub-category?jobSubCategoryId=${id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async AddJobSubCategories(id, payload) {
    try {
      const res = await axios.post(
        `/admin/job-sub-category?jobCategoryId=${id}`,
        payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async getInternDetails() {
    try {
      const res = await axios.get("/admin/intern/list", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  //second
  async toggleInternStatus(payload) {
    try {
      const res = await axios.patch(`/admin/intern?internId=${payload}`, null, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteIntern(payload) {
    try {
      const res = await axios.delete(`/admin/intern?internId=${payload}`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }

  //recruiter
  async getRecruiterDetails() {
    try {
      const res = await axios.get("/admin/recruiter/list", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async toggleRecruiterStatus(payload) {
    try {
      const res = await axios.patch(
        `/admin/recruiter?recruiterId=${payload}`,
        null,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteRecruiter(payload) {
    try {
      const res = await axios.delete(
        `/admin/recruiter?recruiterId=${payload}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  //support mgmt
  async getSupportDetails(action) {
    try {
      const res = await axios.get(
        `/admin/support-contact/list?${
          action.userType === "ALL" ? "" : `userType=${action.userType}`
        }${
          action.supportStatus === "BOTH"
            ? ""
            : `&supportStatus=${action.supportStatus}`
        }`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async changeSupportStatusAsSolved(action) {
    try {
      const res = await axios.patch(
        `/admin/support-contact?supportId=${action.supportId}&confirmationNote=${action.confirmationNote}`,
        null,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  //advertisement
  async getAdvertisementList() {
    try {
      const res = await axios.get("/admin/advertisement/get-all", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addNewAdvertisement(payload) {
    try {
      const res = await axios.post("/admin/advertisement/add", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateAdvertisement(payload) {
    try {
      const res = await axios.put("/admin/advertisement/update", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteAdvertisement(id) {
    try {
      const res = await axios.delete(`/admin/advertisement/delete?id=${id}`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async toggleAdvertisementStatus(id) {
    try {
      const res = await axios.patch(
        `/admin/advertisement/change-status?id=${id}`,
        null,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  //recruiter post fee
  async getRecruiterPostFee() {
    try {
      const res = await axios.get("/admin/recruiter/post-fee/list", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteRecruiterPostFee(id) {
    try {
      const res = await axios.delete(`/admin/recruiter/post-fee?feeId=${id}`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addRecruiterPostFee(payload) {
    try {
      const res = await axios.post("/admin/recruiter/post-fee", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateRecruiterPostFee(action) {
    try {
      const res = await axios.put(
        `/admin/recruiter/post-fee?feeId=${action.id}`,
        action.payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async getPackageDays() {
    try {
      const res = await axios.get("/user/recruiter/post-fee/days", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async toggleRecruiterFeeStatus(id) {
    try {
      const res = await axios.patch(
        `/admin/recruiter/post-fee?feeId=${id}`,
        null,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async getRecruiterFeeByPackageDays(id) {
    try {
      const res = await axios.get(
        `/admin/recruiter/post-fee/list-days?days=${id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }

  //recruiter post
  async getRecruiterPost(id) {
    try {
      const res = await axios.get(
        `/admin/recruiter-post/post-fee?feeId=${id}`,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteRecruiterPost(id) {
    try {
      const res = await axios.delete(`/user/recruiter/post?postId=${id}`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }

  //subscription plan
  async getSubscriptionPlan() {
    try {
      const res = await axios.get("/admin/subscription-plan/list", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async deleteSubscriptionPlan(id) {
    try {
      const res = await axios.delete(`/admin/subscription-plan?planId=${id}`, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async toggleSubscriptionPlanStatus(id) {
    try {
      const res = await axios.patch(
        `/admin/subscription-plan?planId=${id}`,
        null,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addNewSubscriptionPlan(payload) {
    try {
      const res = await axios.post("/admin/subscription-plan", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async updateSubscriptionPlan(action) {
    try {
      const res = await axios.put(
        `/admin/subscription-plan?planId=${action.id}`,
        action.payload,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  //payment mgmt
  async getPaymentList() {
    try {
      const res = await axios.get("/admin/recruiter/post-fee/payment/list", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async approvePayment(id) {
    try {
      const res = await axios.patch(
        `/admin/recruiter/post-fee/payment?paymentId=${id}&status=APPROVED&confirm=true`,
        null,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async rejectPayment(id) {
    try {
      const res = await axios.patch(
        `/admin/recruiter/post-fee/payment?paymentId=${id}&status=REJECTED&confirm=true`,
        null,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async getSubscriptionPlanPaymentList() {
    try {
      const res = await axios.get("/admin/subscription-plan/payment/list", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async approveSubscriptionPlanPayment(id) {
    try {
      const res = await axios.post(
        `/admin/subscription-plan/payment?paymentId=${id}&status=APPROVED&confirm=true`,
        null,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async rejectSubscriptionPlanPayment(id) {
    try {
      const res = await axios.post(
        `/admin/subscription-plan/payment?paymentId=${id}&status=REJECTED&confirm=true`,
        null,
        {
          headers: await HeaderData(),
        }
      );

      return res;
    } catch (err) {
      throw err;
    }
  }
  async getDashboardData() {
    try {
      const res = await axios.get("/admin/dashboard", {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
  async addMassNotification(payload) {
    try {
      const res = await axios.post("/admin/send/mass-notification", payload, {
        headers: await HeaderData(),
      });

      return res;
    } catch (err) {
      throw err;
    }
  }
}
