import { message } from "antd";
import { setCookie, removeCookie } from "../../FrontendHelper";
const INIT_STATE = {
  isLoading: false,
  isSuccessOnLogin: false,
};

const LoginReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADMIN_AUTH_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADMIN_AUTH_SUCCESS": {
      message.success("Login success");
      return {
        ...state,
        isSuccessOnLogin: true,
        isLoading: true,
      };
    }
    case "ADMIN_AUTH_FAILURE": {
      {
        if (action.payload.response.status === 400) {
          message.error(action.payload.response.data.message);
        } else if ((action.payload.message = "Network Error")) {
          message.error("Network Error");
        } else {
          message.error("Unable to login!");
        }
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADMIN_LOGOUT": {
      removeCookie("token");
      setTimeout(() => {
        window.location.replace("/");
      }, [500]);
      return {
        ...state,
        isSuccessOnLogin: false,
        loading: false,
      };
    }
    default:
      return INIT_STATE;
  }
};

export default LoginReducer;
