import Cookies from "js-cookie";

export const setCookie = (key, value) => {
  if (window !== "undefined") {
    Cookies.set(key, value, {
      expires: 1,
    });
  }
};

export const getCookie = (key) => {
  if (window !== "undefined") {
    return Cookies.get(key);
  }
};

export const removeCookie = (key) => {
  if (window !== "undefined") {
    Cookies.remove(key, {
      expires: 1,
    });
  }
};

export const getToken = () => {
  const token = localStorage.getItem("accessToken");

  return token;
};

export const isAuth = () => {
  const cookiePresent = getCookie("accessToken");
  if (cookiePresent) {
    return true;
  } else {
    return false;
  }
};
