import React, { useState } from "react";
import Card from "./../../Card/Card";
import { Form, Input, Button, Space, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;
const { TextArea } = Input;

const AddAdvertisement = (props) => {
  const advertisementState = useSelector((state) => state.AdvertisementReducer);
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [form] = Form.useForm();
  const addAdvertisementContentOnChange = (e) => {
    console.log(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
  };
  const submitAddAdvertisement = (values) => {
    console.log(values);
    let formData = new FormData();
    formData.append("advertisementName", values.advertisementName);
    formData.append("advertisementDetails", values.advertisementDetails);
    formData.append("companyName", values.companyName);
    formData.append("company Details", values.companyDetails);
    formData.append("companyUrl", values.companyUrl);
    formData.append("userType", values.userType);
    formData.append("content", selectedFile);
    console.log(
      "From add advertisement values formData",
      formData.getAll("advertisementName")
    );
    dispatch({
      type: "ADD_NEW_ADVERTISEMENT_REQUEST",
      payload: formData,
    });
    form.resetFields();
    props.onHideAddAdvertisementDrawer();
  };
  console.log("advertisement state", advertisementState);
  return (
    <div style={{ margin: "0 3rem" }}>
      <Card>
        <Form
          form={form}
          onFinish={submitAddAdvertisement}
          style={{ padding: "1rem 2rem" }}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 24,
          }}
        >
          <Form.Item
            label="Advertisement Name"
            name="advertisementName"
            rules={[
              { required: true, message: "Please enter advertisement name!" },
            ]}
          >
            <Input name="advertisementName" placeholder="Ad name" />
          </Form.Item>
          <Form.Item
            label="Advertisement Details"
            name="advertisementDetails"
            rules={[
              {
                required: true,
                message: "Please enter advertisement details!",
              },
            ]}
          >
            <TextArea name="advertisementDetails" placeholder="Ad details" />
          </Form.Item>
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[
              { required: true, message: "Please enter advertisement name!" },
            ]}
          >
            <Input name="companyName" placeholder="Company name" />
          </Form.Item>
          <Form.Item
            label="Company URL"
            name="companyUrl"
            rules={[
              { required: true, message: "Please enter company url!" },
              {
                type: "url",
                message: "Company URL must be a valid url.",
              },
            ]}
          >
            <Input name="companyUrl" />
          </Form.Item>
          <Form.Item
            label="Company Details"
            name="companyDetails"
            rules={[
              { required: true, message: "Please enter company details!" },
            ]}
          >
            <TextArea name="companyDetails" placeholder="Company details" />
          </Form.Item>
          <Form.Item
            label="User Type"
            name="userType"
            rules={[{ required: true, message: "Please select user type!" }]}
          >
            <Select
              style={{
                width: 120,
              }}
            >
              <Option value="ALL">ALL</Option>
              <Option value="INTERN">INTERN</Option>
              <Option value="RECRUITER">RECRUITER</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Content" name="content">
            <input type="file" onChange={addAdvertisementContentOnChange} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 10,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AddAdvertisement;
