import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./Helpers/PrivateRoute";
import "antd/dist/antd.css";
import "./App.css";
import "./Responsiveness.css";

import LoginComponent from "./components/LoginComponent/LoginComponent";
import Dashboard from "./components/Dashboard/Dashboard";
import PrivacyTerms from "./components/PrivacyTerms/PrivacyTerms";
import UserManagement from "./components/UserManagement/UserManagement";
import InternshipCategories from "./components/InternshipCategories/InternshipCategories";
import TermsConditions from "./components/TermsConditions/TermsConditions";
import Support from "./components/Support/Support";
import Advertisement from "./components/Advertisement/Advertisement";
import RecruiterPostFee from "./components/RecruiterPostFee/RecruiterPostFee";
import SubscriptionPlan from "./components/SubscriptionPlan/SubscriptionPlan";
import AdminProfile from "./components/Profile/AdminProfile";
import ChangePassword from "./components/Profile/ChangePassword";
import PaymentManagement from "./components/PaymentManagement/PaymentManagement";
import MassNotification from "./components/MassNotification/MassNotification";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginComponent />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-management"
            element={
              <PrivateRoute>
                <UserManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/internship-categories"
            element={
              <PrivateRoute>
                <InternshipCategories />
              </PrivateRoute>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <PrivateRoute>
                <PrivacyTerms />
              </PrivateRoute>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <PrivateRoute>
                <TermsConditions />
              </PrivateRoute>
            }
          />
          <Route
            path="/support"
            element={
              <PrivateRoute>
                <Support />
              </PrivateRoute>
            }
          />
          <Route
            path="/advertisement"
            element={
              <PrivateRoute>
                <Advertisement />
              </PrivateRoute>
            }
          />
          <Route
            path="/recruiter-post-fee"
            element={
              <PrivateRoute>
                <RecruiterPostFee />
              </PrivateRoute>
            }
          />
          <Route
            path="/subscription-plan"
            element={
              <PrivateRoute>
                <SubscriptionPlan />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <AdminProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/payment-management"
            element={
              <PrivateRoute>
                <PaymentManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/mass-notification"
            element={
              <PrivateRoute>
                <MassNotification />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
