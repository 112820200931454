import React, { useState } from "react";
import "./UserDetails.css";
import { Card, Image } from "antd";

import moment from "moment";

const tabList = [
  {
    key: "tab1",
    tab: "Contact Information",
  },
  {
    key: "tab2",
    tab: "Intern Information",
  },
];

const UserDetails = ({ userDetailsObj }) => {
  const [cardTab, setCardTab] = useState("tab1");
  const onTab1Change = (key) => {
    setCardTab(key);
  };
  console.log("from this", userDetailsObj);
  return (
    <>
      <div className="detailsContainer">
        <div
          style={{ display: "flex", justifyContent: "flex-start", gap: "5rem" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Image
              src={userDetailsObj.userDetails.userImageUrl}
              height="200px"
              width="200px"
              style={{ objectFit: "contain" }}
            />
            <div>
              <h3
                style={{ marginTop: "20px", borderBottom: "1px solid #f0f0f0" }}
              >
                Skills
              </h3>

              {userDetailsObj.skills.map((skill, index) => (
                <p className="detailsParagraph" key={index}>
                  {skill}
                </p>
              ))}
            </div>
            <div>
              <h3 style={{ borderBottom: "1px solid #f0f0f0" }}>Languages</h3>
              {userDetailsObj.language.map((lang, index) => (
                <p className="detailsParagraph" key={index}>
                  {lang}
                </p>
              ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div>
              <p
                style={{ fontWeight: "700", fontSize: "36px", color: "black" }}
              >
                {userDetailsObj.userDetails.fullName}
              </p>
              {userDetailsObj.userDetails.disabled ? (
                <p className="detailsParagraph disabled">Disabled</p>
              ) : (
                <p className="detailsParagraph Active">Active</p>
              )}
            </div>
            <div>
              <h3 style={{ borderBottom: "1px solid #f0f0f0" }}>
                Basic Information
              </h3>
              <p
                className="detailsParagraph"
                style={{
                  display: "flex",
                  gap: "3rem",
                }}
              >
                Username: <span>{userDetailsObj.userDetails.userName}</span>
              </p>
              <p className="detailsParagraph">
                Gender: <span>{userDetailsObj.userDetails.gender}</span>
              </p>
              <p className="detailsParagraph">
                Date of Birth:{" "}
                <span>{userDetailsObj.userDetails.dateOfBirth}</span>
              </p>
            </div>
            <Card
              style={{ width: "100%" }}
              tabList={tabList}
              activeTabKey={cardTab}
              onTabChange={(key) => {
                onTab1Change(key);
              }}
            >
              {cardTab === "tab1" ? (
                <div style={{ marginTop: "10px" }}>
                  <p className="detailsParagraph">
                    Email: <span>{userDetailsObj.userDetails.email}</span>
                  </p>
                  <p className="detailsParagraph">
                    Phone: <span>{userDetailsObj.userDetails.phone}</span>
                  </p>
                  <p className="detailsParagraph">
                    Phone without calling code:{" "}
                    <span>
                      {userDetailsObj.userDetails.phoneWithoutCallingCode}
                    </span>
                  </p>
                </div>
              ) : (
                <div style={{ marginTop: "10px" }}>
                  <p className="detailsParagraph">
                    User type:
                    <span>{userDetailsObj.userDetails.userType}</span>
                  </p>
                  <p className="detailsParagraph">
                    Added Date:{" "}
                    <span>
                      {userDetailsObj.userDetails.addedDate
                        ? moment(userDetailsObj.userDetails.addedDate).format(
                            "DD-MM-YYYY"
                          )
                        : ""}
                    </span>
                  </p>
                  <p className="detailsParagraph">
                    Updated Date:{" "}
                    <span>
                      {userDetailsObj.userDetails.updatedDate
                        ? moment(userDetailsObj.userDetails.updatedDate).format(
                            "DD-MM-YYYY"
                          )
                        : ""}
                    </span>
                  </p>
                  <p className="detailsParagraph">
                    Updated By:{" "}
                    <span>{userDetailsObj.userDetails.updatedBy}</span>
                  </p>
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;

{
  /* (<div>
              <h3>Contact Information</h3>
              <p>
                Email: <span>{userDetailsObj.userDetails.email}</span>
              </p>
              <p>
                Phone: <span>{userDetailsObj.userDetails.phone}</span>
              </p>
              <p>
                Phone without calling code:{" "}
                <span>
                  {userDetailsObj.userDetails.phoneWithoutCallingCode}
                </span>
              </p>
            </div>)
            (<div>
              <h3>Intern Information</h3>
              <p>
                User type: <span>{userDetailsObj.userDetails.usertype}</span>
              </p>
              <p>
                User ID:: <span>{userDetailsObj.userDetails.userId}</span>
              </p>
              <p>
                Intern ID: <span>{userDetailsObj.internId}</span>
              </p>
              <p>
                Added Date: <span>{userDetailsObj.userDetails.addedDate}</span>
              </p>
              <p>
                Updated Date:{" "}
                <span>{userDetailsObj.userDetails.updatedDate}</span>
              </p>
              <p>
                Updated By: <span>{userDetailsObj.userDetails.updatedBy}</span>
              </p>
              <p>
                Status: <span>{userDetailsObj.userDetails.disabled}</span>
              </p>
            </div>) */
}
