import { message } from "antd";
const INIT_STATE = {
  isLoading: false,
  privacyPolicyTerms: {},
  termsAndConditionsTerms: {},
};

const PrivacyAndTermsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "PRIVACY_POLICY_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "PRIVACY_POLICY_REQUEST_SUCCESS": {
      return {
        ...state,
        privacyPolicyTerms: action.payload,
        isLoading: false,
      };
    }
    case "PRIVACY_POLICY_REQUEST_FAILURE": {
      message.error("Unable to load Privacy Policy!");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "PRIVACY_POLICY_UPDATE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "PRIVACY_POLICY_UPDATE_REQUEST_SUCCESS": {
      message.success("Privacy Policy updated");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "PRIVACY_POLICY_UPDATE_REQUEST_FAILURE": {
      message.error("Unable to update Privacy Policy!");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TERMS_AND_CONDITIONS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "TERMS_AND_CONDITIONS_REQUEST_SUCCESS": {
      return {
        ...state,
        termsAndConditionsTerms: action.payload,
        isLoading: false,
      };
    }
    case "TERMS_AND_CONDITIONS_REQUEST_FAILURE": {
      message.error("Unable to load Terms and Conditions");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TERMS_AND_CONDITIONS_UPDATE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "TERMS_AND_CONDITIONS_UPDATE_REQUEST_SUCCESS": {
      message.success("Terms and Conditions updated");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "TERMS_AND_CONDITIONS_UPDATE_REQUEST_FAILURE": {
      message.error("Unable to update Terms and Conditions!");
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return INIT_STATE;
  }
};

export default PrivacyAndTermsReducer;
