import React from "react";
import { Navigate } from "react-router-dom";
import { isAuth } from "../FrontendHelper";

const PrivateRoute = ({ children }) => {
  const isAuthed = isAuth();
  return isAuthed ? children : <Navigate to="/" />;
};

export default PrivateRoute;
