import React, { useEffect, useState } from "react";
import Model from "../UI/Model/Model";
import AddRecruiterPostFee from "./AddRecruiterPostFee/AddRecruiterPostFee";
import EditRecruiterPostFee from "./EditRecruiterPostFee/EditRecruiterPostFee";
import RecruiterPost from "./RecruiterPost/RecruiterPost";
import {
  Button,
  Input,
  Table,
  Drawer,
  Tooltip,
  Space,
  Modal,
  Form,
  Select,
  Popconfirm,
} from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { MdDisabledVisible } from "react-icons/md";
import TableSkeleton from "../UI/TableSkeleton/tableSkelaton";
const { Option } = Select;
const { Search } = Input;
const columns = [
  {
    title: "Package Type",
    dataIndex: "packageType",
  },
  {
    title: "Package Days",
    dataIndex: "packageDays",
  },
  {
    title: "Post Fee",
    dataIndex: "postFee",
  },
  {
    title: "Status",
    dataIndex: "disabled",
  },
  {
    title: "Actions",
    dataIndex: "Actions",
  },
];

const RecruiterPostFee = () => {
  const recruiterPostFeeState = useSelector(
    (state) => state.RecruiterPostFeeReducer
  );
  const dispatch = useDispatch();
  const [record, setRecord] = useState();
  const [isAddRecruiterFeeModalVisible, setIsAddRecruiterFeeModalVisible] =
    useState(false);
  const [isEditRecruiterFeeModalVisible, setIsEditRecruiterFeeModalVisible] =
    useState(false);
  const [isGetRecruiterPostDrawerVisible, setIsGetRecruiterPostDrawerVisible] =
    useState(false);
  useEffect(() => {
    if (isGetRecruiterPostDrawerVisible === false) {
      dispatch({
        type: "GET_RECRUITER_POST_FEE_REQUEST",
      });
    }
    dispatch({
      type: "GET_PACKAGE_DAYS_REQUEST",
    });
  }, [isGetRecruiterPostDrawerVisible]);

  const data =
    recruiterPostFeeState.recruiterPostFeeList &&
    recruiterPostFeeState.recruiterPostFeeList?.map((dataObj) => {
      return {
        key: dataObj.feeId,
        packageType: dataObj.packageType,
        packageDays: dataObj.packageDays,
        postFee: dataObj.postFee,
        disabled: dataObj.disabled ? (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid red",
              textAlign: "center",
              color: "red",
              borderRadius: "3px",
            }}
          >
            Disabled
          </p>
        ) : (
          <p
            style={{
              display: "inline",
              padding: "2px 3px",
              border: "1px solid green",
              textAlign: "center",
              color: "green",
              borderRadius: "3px",
            }}
          >
            Active
          </p>
        ),
        Actions: (
          <Space size="middle">
            <Tooltip title="Edit Post Fee">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsEditRecruiterFeeModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Delete Post Fee">
              <Popconfirm
                title="Are you sure to delete this post fee?"
                onConfirm={() => {
                  deleteJob(dataObj.feeId);
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>
              </Popconfirm>
            </Tooltip>
            <Tooltip title="See Recruiter Post">
              <a
                onClick={() => {
                  setIsGetRecruiterPostDrawerVisible(true);
                  setRecord(dataObj);
                }}
              >
                <div className="bordered">
                  <EyeOutlined />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Enable/Disable Fee Status">
              <a
                onClick={() => {
                  dispatch({
                    type: "TOGGLE_RECRUITER_POST_FEE_STATUS_REQUEST",
                    id: dataObj.feeId,
                  });
                }}
              >
                <div className="bordered">
                  <MdDisabledVisible />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
      };
    });
  const deleteJob = (id) => {
    dispatch({
      type: "DELETE_RECRUITER_POST_FEE_REQUEST",
      id,
    });
  };

  const searchByDays = (days) => {
    dispatch({
      type: "GET_RECRUITER_POST_FEE_BY_PACKAGE_DAYS_REQUEST",
      id: days,
    });
  };
  console.log("from recruiter post fee", recruiterPostFeeState);

  return (
    <>
      <Model>
        <div>
          <div className="advertiseHeader">
            <div className="headerAndButton">
              <h3>Recruiter Post Fee</h3>
              <Button
                type="primary"
                onClick={() => {
                  setIsAddRecruiterFeeModalVisible(true);
                }}
                icon={<PlusOutlined />}
              >
                Add
              </Button>
              <p>Filter Package Days:</p>
              <Select
                style={{ width: 120 }}
                onChange={(e) => {
                  searchByDays(e);
                }}
              >
                {recruiterPostFeeState.packageDays.map((days) => {
                  return (
                    <Option key={days.packageDays}>{days.packageDays}</Option>
                  );
                })}
              </Select>

              <Modal
                title="Add Post Fee"
                visible={isAddRecruiterFeeModalVisible}
                onCancel={() => {
                  setIsAddRecruiterFeeModalVisible(false);
                }}
                footer={null}
              >
                <AddRecruiterPostFee
                  modalHideHandlar={() =>
                    setIsAddRecruiterFeeModalVisible(false)
                  }
                />
              </Modal>
            </div>
            {/* <Search
              size="large"
              width={10}
              placeholder="Search recruiter post fee"
              enterButton
            /> */}
          </div>

          <div>
            {recruiterPostFeeState.isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                bordered
                columns={columns}
                dataSource={data}
                style={{ marginTop: "1.5rem" }}
              />
            )}
          </div>
          <Modal
            title="Edit Post Fee"
            visible={isEditRecruiterFeeModalVisible}
            onCancel={() => {
              setIsEditRecruiterFeeModalVisible(false);
            }}
            footer={null}
          >
            <EditRecruiterPostFee
              record={record}
              modalHideHandlar={() => setIsEditRecruiterFeeModalVisible(false)}
            />
          </Modal>
          <Drawer
            title={`${record?.packageType} / ${record?.packageDays} days`}
            placement="right"
            onClose={() => {
              setIsGetRecruiterPostDrawerVisible(false);
            }}
            visible={isGetRecruiterPostDrawerVisible}
            width={1150}
          >
            <RecruiterPost
              isGetRecruiterPostDrawerVisible={isGetRecruiterPostDrawerVisible}
              dataRecord={record}
              onClose={() => {
                setIsGetRecruiterPostDrawerVisible(false);
              }}
            />
          </Drawer>
        </div>
      </Model>
    </>
  );
};

export default RecruiterPostFee;
