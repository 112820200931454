import React, { useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { useDispatch } from "react-redux";
const { TextArea } = Input;
const { Option } = Select;

const EditRecruiterPostFee = (props) => {
  const dispatch = useDispatch();
  const [option, setOption] = useState(props.record.packageType);
  const [form] = Form.useForm();
  const editPostFeeSubmitHandlar = (values) => {
    const payload = {
      ...values,
      packageDays: +values.packageDays,
      postFee: +values.postFee,
    };
    console.log(props.record.feeId);
    console.log(payload);

    dispatch({
      type: "UPDATE_POST_FEE_REQUEST",
      id: props.record.feeId,
      payload,
    });
    props.modalHideHandlar();
  };
  return (
    <>
      <Form
        form={form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 24,
        }}
        fields={[
          {
            name: ["postFeeType"],
            value: props.record.packageType,
          },
          {
            name: ["packageDays"],
            value: props.record.packageDays,
          },
          {
            name: ["postFee"],
            value: props.record.postFee,
          },
          {
            name: ["postDescription"],
            value: props.record.postDescription,
          },
        ]}
        // initialValues={{
        //   ["postFeeType"]: props.record.postFeeType,
        // }}
        onFinish={editPostFeeSubmitHandlar}
      >
        <Form.Item
          label="Post Fee Type"
          name="postFeeType"
          rules={[{ required: true, message: "Missing post fee type!" }]}
        >
          <Select
            style={{
              width: "150px",
            }}
            onChange={(value) => {
              console.log(value);
              setOption(value);
            }}
          >
            <Option value="FREE">FREE</Option>
            <Option value="POPULAR">POPULAR</Option>
            <Option value="RECOMMENDED">RECOMMENDED</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Package Days"
          name="packageDays"
          rules={[{ required: true, message: "Missing Package days!" }]}
        >
          <Input type="number" name="packageDays" />
        </Form.Item>
        <Form.Item
          label="Post Fee"
          name="postFee"
          rules={[{ required: true, message: "Missing post fee!" }]}
        >
          {option === "FREE" ? (
            <Input type="number" name="postFee" value="0" disabled />
          ) : (
            <Input type="number" name="postFee" />
          )}
        </Form.Item>
        <Form.Item
          label="Post Description"
          name="postDescription"
          rules={[{ required: true, message: "Missing post description!" }]}
        >
          <TextArea name="postDescription" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditRecruiterPostFee;
