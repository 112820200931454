import React, { useEffect, useState } from "react";
import TermsModel from "../UI/termsModel/TermsModel";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getTermsandConditions,
  updateTermsAndConditions,
} from "../../Redux/actions";

const TermsConditions = () => {
  const termsConditionsState = useSelector(
    (state) => state.PrivacyAndTermsReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTermsandConditions());
  }, []);
  const [termsId, setTermsId] = useState();
  const [value, setValue] = useState(
    termsConditionsState.termsAndConditionsTerms.termsAndConditionList?.[0]
      .termsAndCondition
  );
  useEffect(() => {
    if (
      termsConditionsState.termsAndConditionsTerms.termsAndConditionList?.[0]
        .termsAndCondition
    ) {
      setValue(
        termsConditionsState.termsAndConditionsTerms.termsAndConditionList?.[0]
          .termsAndCondition
      );
      setTermsId(
        termsConditionsState.termsAndConditionsTerms.termsAndConditionList?.[0]
          .id
      );
    }
  }, [
    termsConditionsState.termsAndConditionsTerms.termsAndConditionList?.[0]
      .termsAndCondition,
  ]);

  console.log(
    "terms and conditons",
    termsConditionsState.termsAndConditionsTerms
  );
  const updateTermsAndConditionsHandler = () => {
    const updateValue = {
      id: termsId,
      termsAndCondition: value,
    };
    console.log("update value", updateValue);
    dispatch(updateTermsAndConditions(updateValue));
  };
  return (
    <TermsModel
      headerName="Terms and Conditions"
      buttonName="Update Terms & Conditions"
      onClickHandler={updateTermsAndConditionsHandler}
    >
      <ReactQuill theme="snow" value={value} onChange={setValue} />
    </TermsModel>
  );
};

export default TermsConditions;
