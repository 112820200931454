import { Image, Tag } from "antd";
import moment from "moment";
import React from "react";
import "./SeePostDetails.css";

const SeePostDetails = (props) => {
  const { dataRecord } = props;
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          margin: "0rem 20%",
        }}
      >
        <div className="detailsCardDiv">
          <h2 className="header">Post Details</h2>
          <div className="ThreeDivRow">
            {" "}
            <div className="Title">
              Post Name:{" "}
              <span>{props.dataRecord.postDescription.postName}</span>
            </div>
            <div className="Title">
              Post Duration:{" "}
              <span>{props.dataRecord.postDescription.duration}</span>
            </div>
            <div className="Title">
              Salary Type:{" "}
              <span>{props.dataRecord.postDescription.salaryType}</span>
            </div>
          </div>
          <div className="ThreeDivRow">
            <div className="Title">
              Salary: <span>{props.dataRecord.postDescription.salary}</span>
            </div>
            <div className="Title">
              Requirements:{" "}
              <span>
                {props.dataRecord.postDescription.requirements.map(
                  (requirement, index) => {
                    return (
                      <Tag key={index} color="green" style={{ margin: "2px" }}>
                        {requirement}
                      </Tag>
                    );
                  }
                )}
              </span>
            </div>
            <div className="Title">
              Responsibilities:{" "}
              <span>
                {props.dataRecord.postDescription.responsibilities.map(
                  (responsibility, index) => {
                    return (
                      <Tag key={index} color="blue" style={{ margin: "2px" }}>
                        {responsibility}
                      </Tag>
                    );
                  }
                )}
              </span>
            </div>
          </div>
          <div className="ThreeDivRow">
            <div className="Title">
              Benifits:{" "}
              <span>
                {props.dataRecord.postDescription.benefits.map(
                  (benifit, index) => {
                    return (
                      <Tag key={index} style={{ margin: "2px" }}>
                        {benifit}
                      </Tag>
                    );
                  }
                )}
              </span>
            </div>
            <div className="Title">
              Workplaces:{" "}
              <span>
                {props.dataRecord.postDescription.workplaces.map(
                  (workplace, index) => {
                    return (
                      <Tag key={index} style={{ margin: "2px" }}>
                        {workplace}
                      </Tag>
                    );
                  }
                )}
              </span>
            </div>
            <div className="Title">
              Working Days:{" "}
              <span>
                {props.dataRecord.postDescription.workingDays.map(
                  (workingDay, index) => {
                    return (
                      <Tag key={index} style={{ margin: "2px" }}>
                        {workingDay}
                      </Tag>
                    );
                  }
                )}
              </span>
            </div>
          </div>
          <div className="ThreeDivRow">
            <div className="Title">
              Intern Type:{" "}
              <span>
                {props.dataRecord.postDescription.internType.map(
                  (internType, index) => {
                    return (
                      <Tag key={index} style={{ margin: "2px" }}>
                        {internType}
                      </Tag>
                    );
                  }
                )}
              </span>
            </div>
            <div className="Title">
              View Count:{" "}
              <span>{props.dataRecord.postDescription.viewCount}</span>
            </div>
            <div className="Title">
              Has Applied:{" "}
              <span>
                {props.dataRecord.hasApplied ? (
                  <p className="greenTag">YES</p>
                ) : (
                  <p className="redTag">NO</p>
                )}
              </span>
            </div>
          </div>

          <div className="ThreeDivRow">
            <div className="Title">
              Status:{" "}
              <span>
                {props.dataRecord.disabled ? (
                  <p className="redTag">Disabled</p>
                ) : (
                  <p className="greenTag">Active</p>
                )}
              </span>
            </div>
            <div className="Title">
              Bookmarked:{" "}
              <span>
                {props.dataRecord.bookmarked ? (
                  <p className="greenTag">YES</p>
                ) : (
                  <p className="redTag">NO</p>
                )}
              </span>
            </div>
            <div className="Title">
              Added Date:{" "}
              <span>
                {props.dataRecord.addedDate
                  ? moment(props.dataRecord.addedDate).format("YYYY-MM-DD")
                  : ""}
              </span>
            </div>
          </div>
          <div className="ThreeDivRow">
            <div className="Title">
              Updated Date:{" "}
              <span>
                {props.dataRecord.updatedDate
                  ? moment(props.dataRecord.updatedDate).format("YYYY-MM-DD")
                  : ""}
              </span>
            </div>
          </div>
        </div>
        <div className="detailsCardDiv">
          <h2 className="header">Post fee Details</h2>
          <div className="ThreeDivRow">
            <div className="Title">
              Package Type:
              <span>{props.dataRecord.postFeeDetails.packageType}</span>
            </div>
            <div className="Title">
              Package Days:
              <span>{props.dataRecord.postFeeDetails.packageDays}</span>
            </div>
            <div className="Title">
              Post Fee:<span>{props.dataRecord.postFeeDetails.postFee}</span>
            </div>
          </div>
          <div className="ThreeDivRow">
            <div className="Title">
              Post Description:
              <span>{props.dataRecord.postFeeDetails.postDescription}</span>
            </div>
          </div>
        </div>
        <div className="detailsCardDiv">
          <h2 className="header">Company Details</h2>
          <div className="ThreeDivRow">
            <div className="Title">
              Company Name:
              <span>{props.dataRecord.recruiterDetails.companyName}</span>
            </div>
            <div className="Title">
              Location:<span>{props.dataRecord.recruiterDetails.location}</span>
            </div>
            <div className="Title">
              Company Email:
              <span>{props.dataRecord.recruiterDetails.companyEmail}</span>
            </div>
          </div>
          <div className="ThreeDivRow">
            <div className="Title">
              website:
              <span>{props.dataRecord.recruiterDetails.websiteAddress}</span>
            </div>
            <div className="Title">
              Founded Year:
              <span>{props.dataRecord.recruiterDetails.foundedYear}</span>
            </div>
            <div className="Title">
              Number of Employees:
              <span>{props.dataRecord.recruiterDetails.noOfEmployee}</span>
            </div>
          </div>
          <div className="ThreeDivRow">
            <div className="Title">
              Revenue:<span>{props.dataRecord.recruiterDetails.revenue}</span>
            </div>
            <div className="Title">
              Services:{" "}
              <span>
                {props.dataRecord.recruiterDetails.services.map(
                  (service, index) => {
                    return (
                      <Tag key={index} style={{ margin: "2px" }}>
                        {service}
                      </Tag>
                    );
                  }
                )}
              </span>
            </div>
            <div className="Title">
              Document Link:
              <span>
                <a href={props.dataRecord.recruiterDetails.documentUrl}>
                  Click Here
                </a>
              </span>
            </div>
          </div>
          <div className="ThreeDivRow">
            <div className="Title">
              Document Name:
              <span>{props.dataRecord.recruiterDetails.documentName}</span>
            </div>
            <div className="Title">
              Bio:<span>{props.dataRecord.recruiterDetails.bio}</span>
            </div>
          </div>
        </div>
        <div className="detailsCardDiv">
          <h2 className="header">Recruiter Details</h2>
          <div className="ThreeDivRow">
            <div className="Title">
              User Name:{" "}
              <span>
                {props.dataRecord.recruiterDetails.userDetails.userName}
              </span>
            </div>
            <div className="Title">
              Full Name:{" "}
              <span>
                {props.dataRecord.recruiterDetails.userDetails.fullName}
              </span>
            </div>
            <div className="Title">
              Gender:{" "}
              <span>
                {props.dataRecord.recruiterDetails.userDetails.gender}
              </span>
            </div>
          </div>

          <div className="ThreeDivRow">
            <div className="Title">
              Date of Birth:{" "}
              <span>
                {props.dataRecord.recruiterDetails.userDetails.dateOfBirth}
              </span>
            </div>
            <div className="Title">
              Email:{" "}
              <span>{props.dataRecord.recruiterDetails.userDetails.email}</span>
            </div>
            <div className="Title">
              Phone:{" "}
              <span>{props.dataRecord.recruiterDetails.userDetails.phone}</span>
            </div>
          </div>

          <div className="ThreeDivRow">
            <div className="Title">
              Phone Without Calling Code:{" "}
              <span>
                {
                  props.dataRecord.recruiterDetails.userDetails
                    .phoneWithoutCallingCode
                }
              </span>
            </div>
            <div className="Title">
              User Registration Type:{" "}
              <span>
                {
                  props.dataRecord.recruiterDetails.userDetails
                    .userRegistrationType
                }
              </span>
            </div>
            <div className="Title">
              User Type:{" "}
              <span>
                {props.dataRecord.recruiterDetails.userDetails.userType}
              </span>
            </div>
          </div>

          <div className="ThreeDivRow">
            <div className="Title">
              Location:{" "}
              <span>
                {props.dataRecord.recruiterDetails.userDetails.userType}
              </span>
            </div>
            <div className="Title">
              Alert Status:{" "}
              <span>
                {props.dataRecord.recruiterDetails.userDetails.alertStatus ? (
                  <p className="greenTag" style={{ margin: "2px" }}>
                    Active
                  </p>
                ) : (
                  <p className="redTag">Disabled</p>
                )}
              </span>
            </div>
            <div className="Title">
              Push Notification Status:{" "}
              <span>
                {props.dataRecord.recruiterDetails.userDetails
                  .pushNotificationStatus ? (
                  <p className="greenTag">Active</p>
                ) : (
                  <p className="redTag">Disabled</p>
                )}
              </span>
            </div>
          </div>

          <div className="ThreeDivRow">
            <div className="Title">
              Ad Notification Status:{" "}
              <span>
                {props.dataRecord.recruiterDetails.userDetails
                  .adsNotificationStatus ? (
                  <p className="greenTag">Active</p>
                ) : (
                  <p className="redTag">Disabled</p>
                )}
              </span>
            </div>
            <div className="Title">
              Notification Status:{" "}
              <span>
                {props.dataRecord.recruiterDetails.userDetails
                  .notificationStatus ? (
                  <p className="greenTag">Active</p>
                ) : (
                  <p className="redTag">Disabled</p>
                )}
              </span>
            </div>
            <div className="Title">
              User Image:{" "}
              <span>
                <Image
                  src={
                    props.dataRecord.recruiterDetails.userDetails.userImageUrl
                  }
                  height="40px"
                  width="40px"
                />
              </span>
            </div>
          </div>

          <div className="ThreeDivRow">
            <div className="Title">
              User Cover Image:{" "}
              <span>
                <Image
                  src={
                    props.dataRecord.recruiterDetails.userDetails
                      .userCoverImageUrl
                  }
                  height="40px"
                  width="40px"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeePostDetails;
